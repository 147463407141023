import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/AccountSlice";
import appReducer from "./slices/AppSlice";
import earnReducer from "./slices/EarnSlice";
import esbtReducer from "./slices/EsbtSlice";
import eusdReducer from "./slices/EusdSlice";
import vestingReducer from "./slices/VestingSlice";
import messagesReducer from "./slices/MessagesSlice";
import pendingTransactionsReducer from "./slices/PendingTxnsSlice";
import rewardsReducer from "./slices/RewardsSlice";
import multicall from "src/lib/state/multicall";
// reducers are named automatically based on the name field in the slice
// exported in slice files by default as nameOfSlice.reducer

const store = configureStore({
  reducer: {
    //   we'll have state.account, state.bonding, etc, each handled by the corresponding
    // reducer imported from the slice file
    pendingTransactions: pendingTransactionsReducer,
    messages: messagesReducer,
    app: appReducer,
    reward: rewardsReducer,
    account: accountReducer,
    earn: earnReducer,
    eusd: eusdReducer,
    esbt: esbtReducer,
    vesting: vestingReducer,
    multicall: multicall.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
