import { ethers, BigNumber, logger } from "ethers";
import { getTokens } from "src/configs/Tokens";
import {
  BASIS_POINTS_DIVISOR,
  bigNumberify,
  DEFAULT_SLIPPAGE_AMOUNT,
  fetcher,
  getDeltaStr,
  getLeverage,
  getTokenInfo,
  IS_PNL_IN_LEVERAGE_KEY,
  MARGIN_FEE_BASIS_POINTS,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
  useLocalStorageSerializeKey,
} from "src/helpers/Helpers";

import { usePositionReaderContract, useVaultUtilsContract } from "src/hooks/useContract";
import { useWeb3Context } from "src/hooks/web3Context";
import { useSingleCallResult, useSingleContractMultipleData } from "src/lib/hooks/multicall";
import { addresses as ADDRESS } from "src/configs/constants";
import useSWR from "swr";
import { useEffect, useMemo } from "react";
import useBlockNumber from "src/lib/hooks/useBlockNumber";
import { getChartToken } from "src/components/Exchange/ExchangeTVChart";
import BigNumberFloat from "bignumber.js";
import { AddressZero } from "src/constants/address";
import { BN, toBN } from "src/utils/bn";
import { tr } from "date-fns/locale";
type PositionData = {
  account: string;
  collateralToken: string;
  indexToken: string;
  size: BigNumber;
  collateral: BigNumber;
  averagePrice: BigNumber;
  reserveAmount: BigNumber;
  lastUpdateTime: BigNumber;
  aveIncreaseTime: BigNumber;
  entryFundingRateSec: BigNumber;
  entryPremiumRateSec: BigNumber;
  realisedPnl: BigNumber;
  stopLossRatio: BigNumber;
  takeProfitRatio: BigNumber;
  isLong: boolean;
  key: string;
  delta: BigNumber;
  hasProfit: boolean;
  accPremiumFee: BigNumber;
  accFundingFee: BigNumber;
  accPositionFee: BigNumber;
  accCollateral: BigNumber;

  pendingPremiumFee: BigNumber;
  pendingPositionFee: BigNumber;
  pendingFundingFee: BigNumber;

  indexTokenMinPrice: BigNumber;
  indexTokenMaxPrice: BigNumber;
};

type PositionProps = {
  netValue: BigNumber;
  fundingFee: BigNumber;
  positionFee: BigNumber;
  totalFees: BigNumber;
};

type ALLPositionType = PositionProps;

export function useGetpositions(infoTokens: any, pendingPositions: any) {
  const { chainID, address: account } = useWeb3Context();
  const vaultAddress = ADDRESS[chainID]?.Vault?.toString();
  const vaultAddress2 = ADDRESS[chainID]?.Vault_ELP_2?.toString();
  const positionReaderContract = usePositionReaderContract();

  const vaultUtilsContract = useVaultUtilsContract(ADDRESS[chainID]?.VaultUtils);
  const vaultUtilsContract2 = useVaultUtilsContract(ADDRESS[chainID]?.VaultUtils_ELP_2);

  const { result: taxDurationresult } = useSingleCallResult(vaultUtilsContract, "taxDuration");
  const { result: taxDurationresult2 } = useSingleCallResult(vaultUtilsContract2, "taxDuration");
  const { result } = useSingleCallResult(positionReaderContract, "getUserPositions", [
    vaultAddress ?? undefined,
    account ?? undefined,
  ]);
  const { result: result2 } = useSingleCallResult(positionReaderContract, "getUserPositions", [
    vaultAddress2 ?? undefined,
    account ?? undefined,
  ]);

  const WETH = ADDRESS[chainID].NATIVE_TOKEN.toLocaleLowerCase();

  const { pending, closePositionKey } = useMemo(() => {
    const closePositionKey: any = {};
    let pending: any = [];
    if (!pendingPositions || !infoTokens) return { closePositionKey, pending };

    try {
      pending = Object.keys(pendingPositions)
        .map(item => {
          const pendingData = item.split(":");
          if (pendingData.length >= 4) {
            const indexToken = pendingData[2].toLocaleLowerCase();
            let maxPrice = infoTokens[pendingData[2]];
            if (indexToken == WETH) {
              maxPrice = infoTokens[AddressZero];
            }
            return {
              averagePrice: BigNumber.from(0),
              closingFee: BigNumber.from(0),
              collateral: BigNumber.from(0),
              collateralAfterFee: BigNumber.from(0),
              contractKey: "",
              cumulativeFundingRate: BigNumber.from(0),
              delta: BigNumber.from(0),
              entryFundingRate: BigNumber.from(0),
              fundingFee: BigNumber.from(0),
              hasPendingChanges: true,
              hasProfit: false,
              indexToken: pendingData[2],
              pendingFundingFee: BigNumber.from(0),
              pendingPositionFee: BigNumber.from(0),
              indexTokenInfo: maxPrice,
              pendingPremiumFee: BigNumber.from(0),
              isLong: pendingData[3] == "true" ? true : false,
              key: "",
              lastIncreasedTime: 0,
              markPrice: maxPrice?.maxPrice,
              pendingChanges: {
                size: BigNumber.from(1000),
              },
              pendingDelta: BigNumber.from(0),
              positionFee: BigNumber.from(0),
              realisedPnl: BigNumber.from(0),
              size: BigNumber.from(0),
              totalFees: BigNumber.from(0),
            };
          } else if (pendingData.length == 1) {
            closePositionKey[pendingData[0]] = pendingPositions[item];
          }
        })
        .filter(x => !!x);
    } catch (error) { }
    return { closePositionKey, pending };
  }, [pendingPositions, infoTokens]);

  const positionData1 = useMemo(() => {
    if (!result || !result[0] || !infoTokens) return [];
    if (!result[0]) return [];
    const res = result[0]?.filter((item: any) => item.account != ethers.constants.AddressZero);
    const data: PositionData[] = res;
    const taxDuration = taxDurationresult?.[0];

    const includeDelta = window.localStorage.getItem(`[${chainID},"Exchange-swap-is-pnl-in-leverage"]`);
    const showPnlAfterFees = window.localStorage.getItem(`[${chainID},"Exchange-swap-show-pnl-after-fees"]`);

    return data.map(item => {
      const position = {
        ...item,
      } as any;

      const deltaPercentage = item.delta.mul(BASIS_POINTS_DIVISOR).div(item.collateral);
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: item.delta,
        deltaPercentage: deltaPercentage,
        hasProfit: item.hasProfit,
      }) as any;
      position.pendingPremiumFee = item.isLong ? item.pendingPremiumFee : item.pendingPremiumFee;
      position.closingFee = item.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
      position.fundingFee = item.pendingFundingFee ? item.pendingFundingFee : bigNumberify(0);
      position.collateralAfterFee = item.collateral.sub(position.fundingFee);
      let indexToken = infoTokens[item.indexToken];
      position.indexTokenInfo = indexToken;
      position.collateralTokenInfo = infoTokens[item.collateralToken];
      position.collateralToken = item.collateralToken;
      // NATIVE_TOKEN
      if (item.indexToken.toLocaleLowerCase() == WETH) {
        indexToken = infoTokens[AddressZero];
        position.indexTokenInfo = infoTokens[AddressZero];
      } else {
      }
      const markPrice = item.isLong ? item.indexTokenMinPrice : item.indexTokenMaxPrice;
      const markPrice1 = item.isLong ? indexToken?.minPrice : indexToken?.maxPrice;
      position.markPrice = markPrice1 ?? markPrice;
      position.pendingDelta = item.delta;
      if (item.collateral.gt(0)) {
        position.hasLowCollateral =
          position.collateralAfterFee.lt(0) || item.size.div(position.collateralAfterFee.abs()).gt(50);

        if (item.averagePrice.gt(0) && position.markPrice) {
          const priceDelta = item.averagePrice.gt(position.markPrice)
            ? item.averagePrice.sub(position.markPrice)
            : position.markPrice.sub(item.averagePrice);
          position.pendingDelta = item.size.mul(priceDelta).div(item.averagePrice);

          position.delta = position.pendingDelta;

          if (item.isLong) {
            position.hasProfit = position.markPrice.gte(item.averagePrice);
          } else {
            position.hasProfit = position.markPrice.lte(item.averagePrice);
          }
        }

        position.deltaPercentage = position.delta.mul(BASIS_POINTS_DIVISOR).div(item.collateral);

        const { deltaStr, deltaPercentageStr } = getDeltaStr({
          delta: position.delta,
          deltaPercentage: position.deltaPercentage,
          hasProfit: position.hasProfit,
        });

        position.deltaStr = deltaStr;
        position.deltaPercentageStr = deltaPercentageStr;
        position.deltaBeforeFeesStr = deltaStr;

        const currentTime = Math.floor(new Date().getTime() / 1000);
        const levelTime = BigNumber.from(currentTime).sub(item.aveIncreaseTime);
        position.termTax = bigNumberify(0);
        if (taxDuration) {
          if (levelTime.lt(taxDuration)) {
            if (position.hasProfit) {
              const shareTax = toBN(levelTime).div(toBN(taxDuration)).times(toBN(position.delta));
              const termTax = toBN(position.delta).minus(shareTax);
              position.termTax = bigNumberify(termTax.toFixed(0));
            }
          }
        }

        position.totalFees = item.pendingPositionFee
          .add(item.pendingFundingFee)
          .add(item.pendingPremiumFee)
          .add(position.termTax);

        let hasProfitAfterFees;
        let pendingDeltaAfterFees;
        if (position.hasProfit) {
          if (position.delta.gt(position.totalFees)) {
            hasProfitAfterFees = true;
            pendingDeltaAfterFees = position.delta.sub(position.totalFees);
          } else {
            hasProfitAfterFees = false;
            pendingDeltaAfterFees = position.totalFees.sub(position.delta);
          }
        } else {
          if (position.totalFees.gt(0)) {
            hasProfitAfterFees = false;
            pendingDeltaAfterFees = position.delta.add(position.totalFees);
          } else {
            const val = position.totalFees.abs();
            pendingDeltaAfterFees = position.totalFees.abs().sub(position.delta);
            if (position.delta.gt(val)) {
              hasProfitAfterFees = false;
            } else {
              hasProfitAfterFees = true;
            }
          }
        }

        position.hasProfitAfterFees = hasProfitAfterFees;
        position.pendingDeltaAfterFees = pendingDeltaAfterFees;
        position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
          .mul(BASIS_POINTS_DIVISOR)
          .div(position.collateral);

        const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
          delta: position.pendingDeltaAfterFees,
          deltaPercentage: position.deltaPercentageAfterFees,
          hasProfit: hasProfitAfterFees,
        });

        position.deltaAfterFeesStr = deltaAfterFeesStr;
        position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

        if (showPnlAfterFees === "true") {
          position.deltaStr = position.deltaAfterFeesStr;
          position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
        }

        let netValue = position.hasProfit
          ? position.collateral.add(position.pendingDelta)
          : position.collateral.sub(position.pendingDelta);
        netValue = netValue.sub(item.pendingPremiumFee).sub(position.closingFee).sub(item.pendingFundingFee);
        position.netValue = netValue;
      }

      // position.leverage = item.size.div(item.collateral);
      position.leverage = getLeverage({
        size: item.size,
        collateral: item.collateral,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: includeDelta === "true",
      } as any);

      return position;
    });
  }, [result, infoTokens, taxDurationresult]);
  const positionData2 = useMemo(() => {
    if (!result2 || !result2[0] || !infoTokens) return [];
    if (!result2[0]) return [];

    const res = result2[0]?.filter((item: any) => item.account != ethers.constants.AddressZero);
    const data: PositionData[] = res;
    const taxDuration = taxDurationresult2?.[0];

    const includeDelta = window.localStorage.getItem(`[${chainID},"Exchange-swap-is-pnl-in-leverage"]`);
    const showPnlAfterFees = window.localStorage.getItem(`[${chainID},"Exchange-swap-show-pnl-after-fees"]`);

    return data.map(item => {
      const position = {
        ...item,
      } as any;

      const deltaPercentage = item.delta.mul(BASIS_POINTS_DIVISOR).div(item.collateral);
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: item.delta,
        deltaPercentage: deltaPercentage,
        hasProfit: item.hasProfit,
      }) as any;
      position.pendingPremiumFee = item.isLong ? item.pendingPremiumFee : item.pendingPremiumFee;
      position.closingFee = item.size.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
      position.fundingFee = item.pendingFundingFee ? item.pendingFundingFee : bigNumberify(0);
      position.collateralAfterFee = item.collateral.sub(position.fundingFee);
      let indexToken = infoTokens[item.indexToken];
      position.indexTokenInfo = indexToken;
      position.collateralTokenInfo = infoTokens[item.collateralToken];
      position.collateralToken = item.collateralToken;
      // NATIVE_TOKEN
      if (item.indexToken.toLocaleLowerCase() == WETH) {
        indexToken = infoTokens[AddressZero];
        position.indexTokenInfo = infoTokens[AddressZero];
      } else {
      }
      const markPrice = item.isLong ? item.indexTokenMinPrice : item.indexTokenMaxPrice;
      const markPrice1 = item.isLong ? indexToken?.minPrice : indexToken?.maxPrice;
      position.markPrice = markPrice1 ?? markPrice;
      position.pendingDelta = item.delta;
      if (item.collateral.gt(0)) {
        position.hasLowCollateral =
          position.collateralAfterFee.lt(0) || item.size.div(position.collateralAfterFee.abs()).gt(50);

        if (item.averagePrice.gt(0) && position.markPrice) {
          const priceDelta = item.averagePrice.gt(position.markPrice)
            ? item.averagePrice.sub(position.markPrice)
            : position.markPrice.sub(item.averagePrice);
          position.pendingDelta = item.size.mul(priceDelta).div(item.averagePrice);

          position.delta = position.pendingDelta;

          if (item.isLong) {
            position.hasProfit = position.markPrice.gte(item.averagePrice);
          } else {
            position.hasProfit = position.markPrice.lte(item.averagePrice);
          }
        }

        position.deltaPercentage = position.delta.mul(BASIS_POINTS_DIVISOR).div(item.collateral);

        const { deltaStr, deltaPercentageStr } = getDeltaStr({
          delta: position.delta,
          deltaPercentage: position.deltaPercentage,
          hasProfit: position.hasProfit,
        });

        position.deltaStr = deltaStr;
        position.deltaPercentageStr = deltaPercentageStr;
        position.deltaBeforeFeesStr = deltaStr;

        const currentTime = Math.floor(new Date().getTime() / 1000);
        const levelTime = BigNumber.from(currentTime).sub(item.aveIncreaseTime);
        position.termTax = bigNumberify(0);
        if (taxDuration) {
          if (levelTime.lt(taxDuration)) {
            if (position.hasProfit) {
              const shareTax = toBN(levelTime).div(toBN(taxDuration)).times(toBN(position.delta));
              const termTax = toBN(position.delta).minus(shareTax);
              position.termTax = bigNumberify(termTax.toFixed(0));
            }
          }
        }

        position.totalFees = item.pendingPositionFee
          .add(item.pendingFundingFee)
          .add(item.pendingPremiumFee)
          .add(position.termTax);

        let hasProfitAfterFees;
        let pendingDeltaAfterFees;
        if (position.hasProfit) {
          if (position.delta.gt(position.totalFees)) {
            hasProfitAfterFees = true;
            pendingDeltaAfterFees = position.delta.sub(position.totalFees);
          } else {
            hasProfitAfterFees = false;
            pendingDeltaAfterFees = position.totalFees.sub(position.delta);
          }
        } else {
          if (position.totalFees.gt(0)) {
            hasProfitAfterFees = false;
            pendingDeltaAfterFees = position.delta.add(position.totalFees);
          } else {
            const val = position.totalFees.abs();
            pendingDeltaAfterFees = position.totalFees.abs().sub(position.delta);
            if (position.delta.gt(val)) {
              hasProfitAfterFees = false;
            } else {
              hasProfitAfterFees = true;
            }
          }
        }

        position.hasProfitAfterFees = hasProfitAfterFees;
        position.pendingDeltaAfterFees = pendingDeltaAfterFees;
        position.deltaPercentageAfterFees = position.pendingDeltaAfterFees
          .mul(BASIS_POINTS_DIVISOR)
          .div(position.collateral);

        const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
          delta: position.pendingDeltaAfterFees,
          deltaPercentage: position.deltaPercentageAfterFees,
          hasProfit: hasProfitAfterFees,
        });

        position.deltaAfterFeesStr = deltaAfterFeesStr;
        position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;

        if (showPnlAfterFees === "true") {
          position.deltaStr = position.deltaAfterFeesStr;
          position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
        }

        let netValue = position.hasProfit
          ? position.collateral.add(position.pendingDelta)
          : position.collateral.sub(position.pendingDelta);
        netValue = netValue.sub(item.pendingPremiumFee).sub(position.closingFee).sub(item.pendingFundingFee);
        position.netValue = netValue;
      }

      // position.leverage = item.size.div(item.collateral);
      position.leverage = getLeverage({
        size: item.size,
        collateral: item.collateral,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: includeDelta === "true",
      } as any);

      return position;
    });
  }, [result2, infoTokens, taxDurationresult2]);
  const positionData = useMemo(() => positionData1.concat(positionData2), [positionData1, positionData2]);
  return useMemo(() => {
    const data = pending
      .map((item: any) => {
        if (
          item &&
          positionData.filter(x => x.indexToken == item.indexToken && item.isLong == x.isLong).length <= 0 &&
          item
        )
          return item;
      })
      .filter((x: any) => !!x);
    const arr = positionData.filter(x => !closePositionKey[x.key]).concat(data ?? []);
    return arr;
  }, [pending, positionData]);
}
