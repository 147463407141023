import React, { useEffect, useCallback, useMemo } from "react";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import Tooltip from "../../components/Tooltip/Tooltip";

import {
  USD_DECIMALS,
  MAX_LEVERAGE,
  BASIS_POINTS_DIVISOR,
  LIQUIDATION_FEE,
  formatAmount,
  getExplorerUrl,
  formatDateTime,
  deserialize,
  getExchangeRateDisplay,
  bigNumberify,
} from "../../helpers/Helpers";
import { useTrades, useLiquidationsData, useTradeHistory } from "../../Api";
import { getContractAddress } from "../../Addresses";

import "./TradeHistory.css";
import { useWeb3Context } from "src/hooks";

import { getTokenMeta } from "../../configs/Tokens";

const { AddressZero } = ethers.constants;

function getPositionDisplay(increase, indexToken, isLong, sizeDelta) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : "";
  return `
    ${increase ? "Increase" : "Decrease"} ${symbol} ${isLong ? "Long" : "Short"}
    ${increase ? "+" : "-"}${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD`;
}

function getOrderActionTitle(action) {
  let actionDisplay;

  if (action.startsWith("Create")) {
    actionDisplay = "Create";
  } else if (action.startsWith("Cancel")) {
    actionDisplay = "Cancel";
  } else {
    actionDisplay = "Update";
  }

  return `${actionDisplay} Order`;
}

function renderLiquidationTooltip(liquidationData, label) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE);
  const text =
    liquidationData.type === "full"
      ? "This position was liquidated as the max leverage of 100x was exceeded"
      : "Max leverage of 100x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account";
  return (
    <Tooltip
      position="left-top"
      handle={label}
      renderContent={() => (
        <>
          {text}
          <br />
          <br />
          Initial collateral: ${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}
          <br />
          Min required collateral: ${formatAmount(minCollateral, USD_DECIMALS, 2, true)}
          <br />
          Funding Fee: ${formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}
          <br />
          PnL: -${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}
          {liquidationData.type === "full" && <div>Liquidation fee: ${formatAmount(LIQUIDATION_FEE, 30, 2, true)}</div>}
        </>
      )}
    />
  );
}

function getLiquidationData(liquidationsDataMap, key, timestamp) {
  return liquidationsDataMap && liquidationsDataMap[`${key}:${timestamp}`];
}

export default function TradeHistory(props) {
  const { account, infoTokens, getTokenInfo, nativeTokenAddress, chainID } = props;
  // const { chainID } = useWeb3Context();
  // const { trades, updateTrades } = useTrades(chainID, account, props.forSingleAccount);
  const trades = useTradeHistory(chainID, account);
  const liquidationsData = useLiquidationsData(chainID, account);
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null;
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`;
      memo[liquidationKey] = item;
      return memo;
    }, {});
  }, [liquidationsData]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     updateTrades(undefined, true);
  //   }, 10 * 1000);
  //   return () => clearInterval(interval);
  // }, [updateTrades]);

  const getMsg = useCallback(
    trade => {
      // const defaultMsg = trade.action;
      const defaultMsg = "";

      if (trade.action === "BuyUSDG") {
        const token = getTokenMeta(chainID, trade.token);
        if (!token) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(trade.tokenAmount, token.decimals, 4, true)} ${token.symbol} for ${formatAmount(
          trade.usdgAmount,
          18,
          4,
          true,
        )} USDX`;
      }

      if (trade.action === "SellUSDG") {
        const token = getTokenMeta(chainID, trade.token);
        if (!token) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(trade.usdgAmount, 18, 4, true)} USDX for ${formatAmount(
          trade.tokenAmount,
          token.decimals,
          4,
          true,
        )} ${token.symbol}`;
      }
      // Router.sol
      if (trade.action === "Swap") {
        const tokenIn = getTokenMeta(chainID, trade.tokenIn);
        const tokenOut = getTokenMeta(chainID, trade.tokenOut);

        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(trade.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol} for ${formatAmount(
          trade.amountOut,
          tokenOut.decimals,
          4,
          true,
        )} ${tokenOut.symbol}`;
      }

      if (trade.action === "CreateIncreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(trade.sizeDelta).eq(0)) {
          return `Request deposit into ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"}`;
        }

        return `Request increase ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"}, +${formatAmount(
          trade.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD, Acceptable Price: ${trade.isLong ? "<" : ">"} ${formatAmount(
          trade.acceptablePrice,
          USD_DECIMALS,
          2,
          true,
        )} USD`;
      }

      if (trade.action === "CreateDecreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);

        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(trade.sizeDelta).eq(0)) {
          return `Request withdrawal from ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"}`;
        }

        return `Request decrease ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"}, -${formatAmount(
          trade.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD, Acceptable Price: ${trade.isLong ? ">" : "<"} ${formatAmount(
          trade.acceptablePrice,
          USD_DECIMALS,
          2,
          true,
        )} USD`;
      }

      if (trade.action === "CancelIncreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(trade.sizeDelta).eq(0)) {
          return (
            <>
              Could not execute deposit into {indexToken.symbol} {trade.isLong ? "Long" : "Short"}
            </>
          );
        }

        return (
          <>
            Could not increase {indexToken.symbol} {trade.isLong ? "Long" : "Short"},
            {`+${formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
            {trade.isLong ? "<" : ">"}&nbsp;
            <Tooltip
              position="left-top"
              handle={`${formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => <>Try increasing the "Allowed Slippage", under the Settings menu on the top right</>}
            />
          </>
        );
      }

      if (trade.action === "CancelDecreasePosition") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);

        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(trade.sizeDelta).eq(0)) {
          return `Could not execute withdrawal from ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"}`;
        }

        return (
          <>
            Could not decrease {indexToken.symbol} {trade.isLong ? "Long" : "Short"},
            {`+${formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
            {trade.isLong ? ">" : "<"}&nbsp;
            <Tooltip
              position="left-top"
              handle={`${formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => <>Try increasing the "Allowed Slippage", under the Settings menu on the top right</>}
            />
          </>
        );
      }

      if (trade.action === "IncreasePosition-Long" || trade.action === "IncreasePosition-Short") {
        if (trade.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(trade.sizeDelta).eq(0)) {
          return `Deposit ${formatAmount(trade.collateralDelta, USD_DECIMALS, 2, true)} USD into ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"
            }`;
        }
        return `Increase ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"}, +${formatAmount(
          trade.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD, ${indexToken.symbol} Price: ${formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD`;
      }

      if (trade.action === "DecreasePosition-Long" || trade.action === "DecreasePosition-Short") {
        if (trade.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(trade.sizeDelta).eq(0)) {
          return `Withdraw ${formatAmount(trade.collateralDelta, USD_DECIMALS, 2, true)} USD from ${indexToken.symbol
            } ${trade.isLong ? "Long" : "Short"}`;
        }
        const isLiquidation = trade.flags?.isLiquidation;
        const liquidationData = getLiquidationData(liquidationsDataMap, trade.key, trade.timestamp);

        if (isLiquidation && liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, "Partial Liquidation")} {indexToken.symbol}{" "}
              {trade.isLong ? "Long" : "Short"}, -{formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)} USD,{" "}
              {indexToken.symbol}&nbsp; Price: ${formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD
            </>
          );
        }
        const actionDisplay = isLiquidation ? "Partially Liquidated" : "Decreased";
        return `
        ${actionDisplay} ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"},
        -${formatAmount(trade.sizeDelta, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(trade.acceptablePrice, USD_DECIMALS, 2, true)} USD
      `;
      }

      if (trade.action === "LiquidatePosition-Long" || trade.action === "LiquidatePosition-Short") {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, trade.key, trade.timestamp);
        if (liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(liquidationData, "Liquidated")} {indexToken.symbol}{" "}
              {trade.isLong ? "Long" : "Short"}, -{formatAmount(trade.size, USD_DECIMALS, 2, true)} USD,&nbsp;
              {indexToken.symbol} Price: ${formatAmount(trade.markPrice, USD_DECIMALS, 2, true)} USD
            </>
          );
        }
        return `
        Liquidated ${indexToken.symbol} ${trade.isLong ? "Long" : "Short"},
        -${formatAmount(trade.size, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(trade.markPrice, USD_DECIMALS, 2, true)} USD
      `;
      }

      if (["ExecuteIncreaseOrder", "ExecuteDecreaseOrder"].includes(trade.action)) {
        const order = deserialize(trade.order);
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const longShortDisplay = trade.isLong ? "Long" : "Short";
        const executionPriceDisplay = formatAmount(trade.executionPrice, USD_DECIMALS, 2, true);
        const sizeDeltaDisplay = `${trade.type === "Increase" ? "+" : "-"}${formatAmount(
          trade.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )}`;

        return `
        Execute Order: ${trade.type} ${indexToken.symbol} ${longShortDisplay}
        ${sizeDeltaDisplay} USD, Price: ${executionPriceDisplay} USD
      `;
      }

      if (
        [
          "CreateIncreaseOrder",
          "CancelIncreaseOrder",
          "UpdateIncreaseOrder",
          "CreateDecreaseOrder",
          "CancelDecreaseOrder",
          "UpdateDecreaseOrder",
        ].includes(trade.action)
      ) {
        const indexToken = getTokenMeta(chainID, trade.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const increase = trade.action.includes("Increase");
        const priceDisplay = `${trade.triggerAboveThreshold ? ">" : "<"} ${formatAmount(
          trade.triggerPrice,
          USD_DECIMALS,
          2,
          true,
        )}`;
        return `
        ${getOrderActionTitle(trade.action)}:
        ${getPositionDisplay(increase, indexToken, trade.isLong, trade.sizeDelta)},
        Price: ${priceDisplay}
      `;
      }

      if (trade.action === "ExecuteSwapOrder") {
        const nativeTokenAddress = getContract(chainID, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, trade.path[0] === nativeTokenAddress ? AddressZero : trade.path[0]);
        const toToken = getTokenInfo(infoTokens, trade.shouldUnwrap ? AddressZero : trade.path[trade.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const fromAmountDisplay = formatAmount(trade.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true);
        const toAmountDisplay = formatAmount(trade.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true);
        return `
        Execute Order: Swap ${fromAmountDisplay} ${fromToken.symbol} for ${toAmountDisplay} ${toToken.symbol}
      `;
      }

      if (["CreateSwapOrder", "UpdateSwapOrder", "CancelSwapOrder"].includes(trade.action)) {
        const order = deserialize(trade.order);
        const nativeTokenAddress = getContract(chainID, "NATIVE_TOKEN");
        const fromToken = getTokenInfo(infoTokens, trade.path[0] === nativeTokenAddress ? AddressZero : trade.path[0]);
        const toToken = getTokenInfo(infoTokens, trade.shouldUnwrap ? AddressZero : trade.path[trade.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const amountInDisplay = fromToken
          ? formatAmount(trade.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : "";
        const minOutDisplay = toToken
          ? formatAmount(trade.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : "";

        return `
        ${getOrderActionTitle(trade.action)}:
        Swap ${amountInDisplay} ${fromToken?.symbol || ""} for ${minOutDisplay} ${toToken?.symbol || ""},
        Price: ${getExchangeRateDisplay(trade.triggerRatio, fromToken, toToken)}`;
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, chainID, liquidationsDataMap],
  );
  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return [];
    }

    return trades
      .map(trade => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter(trade => trade.msg);
  }, [trades, getMsg]);

  return (
    <div className="TradeHistory">
      {tradesWithMessages.length === 0 && <div className="TradeHistory-row App-box">No trades yet</div>}
      {tradesWithMessages.length > 0 &&
        tradesWithMessages.map((trade, index) => {
          const txUrl = getExplorerUrl(chainID) + "tx/" + trade.txhash;
          let msg = getMsg(trade);

          if (!msg) {
            return null;
          }

          return (
            <div className="TradeHistory-row App-box App-box-border" key={index}>
              <div>
                <div className="muted TradeHistory-time">
                  {formatDateTime(trade.timestamp)}
                  {(!account || account.length === 0) && (
                    <span>
                      {" "}
                      (<Link to={`/actions/${trade.account}`}>{trade.account}</Link>)
                    </span>
                  )}
                </div>
                <a className="plain" href={txUrl} target="_blank" rel="noopener noreferrer">
                  {msg}
                </a>
              </div>
            </div>
          );
        })}
    </div>
  );
}
