/* eslint-disable global-require */
import { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Web3ContextProvider } from "./hooks/web3Context";
import App from "./App";
import store from "./store";
import { MulticallUpdater } from "src/lib/state/multicall";
import { BlockNumberProvider } from "src/lib/hooks/useBlockNumber";
const Updaters = () => {
  return (
    <>
      <MulticallUpdater />
    </>
  );
};

export default function Root() {
  return (
    <Provider store={store}>
      <HashRouter>
        <Web3ContextProvider>
          <BlockNumberProvider>
            <Updaters />
            <App />
          </BlockNumberProvider>
        </Web3ContextProvider>
      </HashRouter>
    </Provider>
  );
}
