import '../components/Messages/messages.scss'
import Close from '../assets/images/message/close.svg'
import Vector from '../assets/images/message/Vector.svg'
import Vector2 from '../assets/images/message/Vector2.svg'
import Vector3 from '../assets/images/message/Vector3.svg'
import Vector4 from '../assets/images/message/Vector4.svg'
// import Success from '../assets/images/message/success.svg'
// import Fail from '../assets/images/message/fail.svg'
import Waiting from "../assets/images/loading/waitingForConfirmation.svg";
import Processing from "../assets/images/loading/processingTransaction.svg";
import Fail from "../assets/images/failMsg.svg";
import Success from "../assets/images/successMsg.svg";
import View from "../assets/images/view.svg";
import Success2 from "../assets/images/successMsg2.svg";
import X from "../assets/images/x.svg";
import { helperToast } from './Helpers'
import { toast } from "react-toastify";
import { Box } from '@material-ui/core'

const handleClose = () => {
  toast.dismiss();
}

export const toastError = (message) => {
  // helperToast.error(
  //   <div className='messageInfo bg2'>
  //     <Box display='flex' justifyContent='space-between' alignItems='flex-start' className="message">
  //       <Box display='flex' className="flex-1 message-l">
  //         <img src={Fail} className="messageIcon" />
  //         <img src={Vector3} />
  //         <div className="" style={{ margin: '16px auto 16px 32px' }}>
  //           <div className="messageTitle font-20 font-weight-5">Oh snap!</div>
  //           <div className="messageContent font-14 font-weight-4" >{message}</div>
  //         </div>
  //       </Box>
  //       <img src={Close} className="mt-26 ml-12" onClick={handleClose} />
  //     </Box>
  //   </div>
  // );
  helperToast.error(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="flex-start">
          <img src={Fail} height={80} />
          <div className="message">
            <div className="font-14 font-weight-b">Oh snap!</div>
            <div className="messageText mt-6">{message}</div>
          </div>
        </Box>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
    </div>
  )
}

export const toastSuccess = (message) => {
  // helperToast.success(
  //   <div className='messageInfo bg1'>
  //     <Box display='flex' justifyContent='space-between' alignItems='flex-start' className="message">
  //       <Box display='flex' className="flex-1 message-l">
  //         <img src={Success} className="messageIcon" />
  //         <img src={Vector} />
  //         <div className="" style={{ margin: '16px auto 16px 32px' }}>
  //           <div className="messageTitle font-20 font-weight-5">Well done!</div>
  //           <div className="messageContent font-14 font-weight-4" >{message}</div>
  //         </div>
  //       </Box>
  //       <img src={Close} className="mt-26 ml-12" onClick={handleClose} />
  //     </Box>
  //   </div>
  // );
  helperToast.success(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="flex-start">
          <img src={Success} height={80} />
          <div className="message">
            <div className="font-14 font-weight-b">Well done!</div>
            <div className="messageText mt-6">{message}</div>
          </div>
        </Box>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
    </div>
  )
}

export const toastWaiting = (message) => {
  helperToast.waiting(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="flex-start">
          <img src={Waiting} height={80} />
          <div className="message">
            <div className="font-14 font-weight-b">Waiting for confirmation</div>
            <div className="messageText mt-6">{message}</div>
          </div>
        </Box>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
    </div>
  )
}

export const toastProcessing = (message) => {
  helperToast.processing(
    <div className="toastBox">
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box display="flex" alignItems="flex-start">
          <img src={Processing} height={80} />
          <div className="message">
            <div className="font-14 font-weight-b">Processing transaction</div>
            <div className="messageText mt-6">{message}</div>
          </div>
        </Box>
        <img src={X} className="close" onClick={handleClose} />
      </Box>
    </div>
  )
}

export const toastTransaction = (title, url, message) => {
  helperToast.transaction(
    <div className="transactionToast">
      <Box display="flex" justifyContent="flex-end">
        <img src={X} onClick={handleClose} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" className="mt-48">
        <img src={Success2} height={80} />
        <div className="info mt-24 text-center">
          <div className="color4 font-16 font-weight-6">{title}</div>
          <div className="mt-8 color9 font-14">{message}</div>
        </div>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" className="mt-16">
        <img src={View} />
        <a href={url} target="_blank" className="ml-12 color18 font-14">
          View On BscScan
        </a>
      </Box>
    </div>
  )
}

export const toastTransaction2 = (title, url, message) => {
  helperToast.transactionUpdate(
    <div className="transactionToast">
      <Box display="flex" justifyContent="flex-end">
        <img src={X} onClick={handleClose} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" className="mt-48">
        <img src={Success2} height={80} />
        <div className="info mt-24 text-center">
          <div className="color4 font-16 font-weight-6">{title}</div>
          <div className="mt-8 color9 font-14">{message}</div>
        </div>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" className="mt-16">
        <img src={View} />
        <a href={url} target="_blank" className="ml-12 color18 font-14">
          View On BscScan
        </a>
      </Box>
    </div>
  )
}  