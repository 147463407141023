import axios from "axios";
import { ethers } from "ethers";
import { getServerUrl } from "src/helpers/Helpers";
import { IBaseAsyncThunk } from "src/slices/interfaces";
import useSWR from "swr";

import { abi as PairABI } from "../abis/Pair.json";
import { addresses } from "../configs/constants";

export async function getEdePrice({ networkID, provider }: IBaseAsyncThunk) {
  const pairContract = new ethers.Contract(addresses[networkID].EDE_LP, PairABI, provider);
  const token0 = await pairContract.token0()
  const token0Contract = new ethers.Contract(token0, PairABI, provider);
  const decimal0 = await token0Contract.decimals()
  const token1 = await pairContract.token1()
  const token1Contract = new ethers.Contract(token1, PairABI, provider);
  const decimal1 = await token1Contract.decimals()
  const reserves = await pairContract.getReserves();
  let edePrice;
  if (token0.toString().toLowerCase() == addresses[networkID].EDE.toLowerCase()) {
    edePrice = reserves[1] / reserves[0] * Math.pow(10, (decimal0 - decimal1));
  } else {
    edePrice = reserves[0] / reserves[1] * Math.pow(10, (decimal1 - decimal0));
  }

  if (networkID == 42161) {    // || networkID == 421613
    const indexPricesUrl = getServerUrl(networkID, "/prices");
    const res = await axios.get(indexPricesUrl)
    const ethPrice = Number(ethers.utils.formatUnits(res.data.ETHUSDT, 30))
    return edePrice * ethPrice
  }

  return edePrice;
}