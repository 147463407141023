import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { et } from "date-fns/locale";
import { ethers } from "ethers";
import moment from "moment";
import { addresses } from "src/configs/constants";
import { getTokens } from "src/configs/Tokens";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { abi as EsbtABI } from "../abis/ESBT.json";
import { abi as InfoHelperABI } from "../abis/InfoHelper.json";
import { abi as ShareholdersRebateABI } from "../abis/ShareholdersRebate.json";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { setAll } from "../helpers";
import { RootState } from "../store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { setCookie } from "./cookie";

export const loadEsbtDetails = createAsyncThunk(
  "esbt/loadEsbt",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    console.log("loadEsbtDetails---");
    const signer = provider.getSigner();
    const currentBlock = await provider.getBlockNumber();
    const ESBTContract = new ethers.Contract(addresses[networkID].ESBT, EsbtABI, provider);
    const InfoHelperContract = new ethers.Contract(addresses[networkID].InfoHelper, InfoHelperABI, provider);
    // const ShareholdersRebateContract = new ethers.Contract(addresses[networkID].ShareholdersRebate, ShareholdersRebateABI, provider);
    const RewardRouterContract = new ethers.Contract(addresses[networkID].RewardRouter, RewardRouterABI, provider);
    const claimableESBTEUSD = await RewardRouterContract.claimableESBTEUSD(address || PLACEHOLDER_ACCOUNT);
    // console.log("claimableESBTEUSD", claimableESBTEUSD)
    const disocunt_eusd = claimableESBTEUSD[0]
    const rebate_eusd = claimableESBTEUSD[1]
    // const startTime = await ShareholdersRebateContract.timeStart();
    // const stopTime = await ShareholdersRebateContract.timeStop();
    const basicInfo = await InfoHelperContract.getBasicInfo(addresses[networkID].ESBT, address || PLACEHOLDER_ACCOUNT);
    // console.log("basicInfo", basicInfo);
    const userInfoArr1 = basicInfo[0];
    const nickName = userInfoArr1[0];
    const refCode = userInfoArr1[1];
    // if (refCode) setCookie("refCode", refCode)
    const userInfoArr2 = basicInfo[2];
    const feeDiscount = userInfoArr2[0];
    const feeRebate = userInfoArr2[1];
    const userSizeSum = userInfoArr2[2];
    const score = userInfoArr2[9];
    const rank = userInfoArr2[10];
    const rankUpdateTime = userInfoArr2[15];

    const defaultRefCode = await ESBTContract.getRefCode(addresses[networkID].ESBT);
    // setCookie("defaultRefCode", defaultRefCode)
    var currentRound = 0;
    const isESBT = await ESBTContract.balanceOf(address || PLACEHOLDER_ACCOUNT);
    const createTime = await ESBTContract.createTime(address || PLACEHOLDER_ACCOUNT);

    // const referalState = await ShareholdersRebateContract.getReferalState(address || PLACEHOLDER_ACCOUNT);
    // // console.log(referalState)
    // const invitedUserAmount_meet = Number(referalState[0]);
    // const activityCompletionArr = referalState[1];
    // // console.log(activityCompletionArr)
    // const maxRound = await ShareholdersRebateContract.maxRound();
    // let claimedAmount = 0;
    // if (rank > 0) {
    //   claimedAmount = await ShareholdersRebateContract.claimedAmount(address || PLACEHOLDER_ACCOUNT, currentRound);
    // }

    let isCompleted = false;
    let completeness = 0;
    // if (activityCompletionArr.length > 0) {
    //   for (let i = 0; i < activityCompletionArr.length; i++) {
    //     if (Number(ethers.utils.formatUnits(activityCompletionArr[i], 4)) == 1) {
    //       if (claimedAmount * 1 > 0) {
    //         isCompleted = true;
    //         if (i + 1 < activityCompletionArr.length) {
    //           if (i + 1 < maxRound) currentRound = i + 1;
    //         }
    //       }
    //     }
    //   }
    //   completeness = activityCompletionArr[currentRound];
    // }

    // const rewardAmount = await ShareholdersRebateContract.rewardAmount(currentRound);
    // const rewardTokenAddress = await ShareholdersRebateContract.rewardToken(currentRound);

    // const invitedUserAddressArr = referalState[2];
    // const invitedUserTradingVolumesArr = referalState[3];
    // const invitedUserIsMeetArr = referalState[4];
    let invitedUserMeetedNum = 0;
    // if (invitedUserIsMeetArr.length > 0) {
    //   for (let j = 0; j < invitedUserIsMeetArr.length; j++) {
    //     if (invitedUserIsMeetArr[j]) {
    //       invitedUserMeetedNum++;
    //     }
    //   }
    // }

    return {
      defaultRefCode,
      inviteCode: refCode ? refCode : "--",
      score: ethers.utils.formatEther(score),
      rank,
      userSizeSum: ethers.utils.formatUnits(userSizeSum, 30),
      nickName,
      createTime,
      isESBT: isESBT > 0 ? true : false,
      feeDiscount: ethers.utils.formatUnits(feeDiscount, 6),
      feeRebate: ethers.utils.formatUnits(feeRebate, 6),
      completeness: ethers.utils.formatUnits(completeness, 4),
      currentRound,
      rankUpdateTime,
      // rewardAmount: ethers.utils.formatEther(rewardAmount),
      // rewardTokenAddress,
      // stopTime,
      // startTime,
      disocunt_eusd: ethers.utils.formatEther(disocunt_eusd),
      rebate_eusd: ethers.utils.formatEther(rebate_eusd),
      isCompleted
    };
  },
);

const initialState = {
  loading: false,
};

const esbtSlice = createSlice({
  name: "esbt",
  initialState,
  reducers: {
    fetchEsbtSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadEsbtDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadEsbtDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadEsbtDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.esbt;
export default esbtSlice.reducer;
export const { fetchEsbtSuccess } = esbtSlice.actions;
export const getEusdState = createSelector(baseInfo, esbt => esbt);
