import { JsonRpcProvider, StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { error } from "src/slices/MessagesSlice";
import Web3Modal, { injected } from "web3modal";
import { DeFiConnectProvider } from "@deficonnect/provider";
import { NETWORK_LIST, RPC_URLS, SUPPORTED_CHAIN_IDS } from "src/constants/chains";
import { toastError } from "src/helpers/toastHelpers";

/*
  Types
*/
type onChainProvider = {
  connect: () => void;
  disconnect: () => void;
  provider: JsonRpcProvider;
  address: string;
  connected: Boolean;
  web3Modal: Web3Modal;
  chainID: number;
  Error: boolean;
};

export type Web3ContextData = {
  onChainProvider: onChainProvider;
} | null;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      "useWeb3Context() can only be used inside of <Web3ContextProvider />, " + "please declare it at a higher level.",
    );
  }
  const { onChainProvider } = web3Context;
  return useMemo(() => {
    return { ...onChainProvider };
  }, [web3Context]);
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  // NOTE (appleseed): if you are testing on rinkeby you need to set chainId === 4 as the default for non-connected wallet testing...
  // ... you also need to set getTestnetURI() as the default uri state below
  const walletKey = window.localStorage.getItem("SELECTED_NETWORK");
  const [chainID, setChainID] = useState(Number(walletKey || process.env.REACT_APP_NETWORKID));
  const [Error, setError] = useState(false);
  const [address, setAddress] = useState("");

  const [provider, setProvider] = useState<JsonRpcProvider>(new StaticJsonRpcProvider(RPC_URLS[chainID]));

  const [web3Modal, setWeb3Modal] = useState<Web3Modal>(
    new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              56: "https://bsc-dataseed3.ninicoin.io",
              42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
            },
            network: "binance",
            infuraId: "2b1a2f33060b443b9a6beaf1e6e0b561",
          },
        },
        "custom-deficonnect": {
          display: {
            name: "Crypto.com DeFi Wallet",
            description: "Connect to Crypto.com DeFi Wallet",
            logo: "https://crypto.com/defi/swap/favicon.c5a5b109.png",
          },
          package: DeFiConnectProvider,
          options: {
            rpcUrls: {
              56: "https://bsc-dataseed3.ninicoin.io",
              42161: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
            },
            chainType: "eth",
            chainId: "56",
          },
          connector: async (ProviderPackage, options) => {
            const provider = new ProviderPackage(options);
            await provider.enable();
            return provider;
          },
        },
      },
    }),
  );

  const hasCachedProvider = (): Boolean => {
    if (!web3Modal) return false;
    if (!web3Modal.cachedProvider) return false;
    return true;
  };

  // NOTE (appleseed): none of these listeners are needed for Backend API Providers
  // ... so I changed these listeners so that they only apply to walletProviders, eliminating
  // ... polling to the backend providers for network changes
  const _initListeners = useCallback(
    rawProvider => {
      if (!rawProvider.on) {
        return;
      }
      rawProvider.on("accountsChanged", async (accounts: string[]) => {
        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("chainChanged", async (chain: number) => {
        if (SUPPORTED_CHAIN_IDS.indexOf(Number(chain)) != -1) {
          window.localStorage.setItem("SELECTED_NETWORK", Number(chain).toFixed());
        }

        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("network", (_newNetwork: any, oldNetwork: any) => {
        if (!oldNetwork) return;
        setTimeout(() => window.location.reload(), 1);
      });
    },
    [provider],
  );

  /**
   * throws an error if networkID is not 1 (mainnet) or 4 (rinkeby)
   */
  const _checkNetwork = (otherChainID: number): Boolean => {
    if (chainID !== otherChainID) {
      console.warn("You are switching networks");
      if (otherChainID === Number(process.env.REACT_APP_NETWORKID)) {
        setChainID(otherChainID);
        return true;
      }
      setAddress("");
      setConnected(false);
      return false;
    }
    return true;
  };
  // const _checkNetwork = (otherChainID: number): Boolean => {
  //   // console.log(otherChainID, typeof otherChainID, "otherChainID");
  //   typeof otherChainID == "string" ? setChainID(parseInt(otherChainID, 16)) : setChainID(otherChainID);
  //   return true;
  // };

  // connect - only runs for WalletProviders
  const connect = useCallback(
    async (initialization = false) => {
      try {
        const rawProvider = await web3Modal.connect();
        await web3Modal.toggleModal(); // open toggleModal

        // await rawProvider.enable();

        // new _initListeners implementation matches Web3Modal Docs
        // ... see here: https://github.com/Web3Modal/web3modal/blob/2ff929d0e99df5edf6bb9e88cff338ba6d8a3991/example/src/App.tsx#L185
        _initListeners(rawProvider);

        const connectedProvider = new Web3Provider(rawProvider, "any");
        const chainId = await connectedProvider.getNetwork().then(network => network.chainId);
        const connectedAddress = await connectedProvider.getSigner().getAddress();
        let chainIds;
        if (window.ethereum) {
          chainIds = Number(await window.ethereum.request({ method: "eth_chainId" }));
        }
        console.log("chainIds", chainIds);

        if (SUPPORTED_CHAIN_IDS.indexOf(chainId ?? chainIds) == -1) {
          setError(true);
          setChainID(chainId ?? chainIds);
          const work = NETWORK_LIST.filter(network => network.chainId === chainID)[0];
          if (!work) return error("Error!");
          if (initialization) {
            switchNetwork2(work.chainId, work.chainName, work.nativeCurrency, work.rpcUrl, work.blockExplorerUrl);
          }
          setAddress("");
          setConnected(false);
        } else {
          setAddress(connectedAddress);
          setProvider(connectedProvider);
          setConnected(true);
        }
        return connectedProvider;
      } catch (e: any) {
        console.log(e, e.message);
      }
    },
    [provider, web3Modal, connected],
  );

  const switchNetwork2 = async (
    chainId: number,
    chainName: string,
    nativeCurrency: string,
    rpcUrl: string,
    blockExplorerUrl: string,
  ) => {
    try {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
        window.localStorage.setItem("SELECTED_NETWORK", Number(chainId).toFixed());
        setChainID(Number(chainId));
      } catch (switchError: any) {
        if (switchError.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName,
                nativeCurrency: {
                  name: nativeCurrency,
                  symbol: nativeCurrency,
                  decimals: 18,
                },
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [blockExplorerUrl],
              },
            ],
          });
          window.localStorage.setItem("SELECTED_NETWORK", Number(chainId).toFixed());
          setChainID(Number(chainId));
        }
      }
    } catch (addError) {
      console.log("switchNetwork2", addError);
      // handle "add" error
    }
  };

  const disconnect = useCallback(async () => {
    console.log("disconnecting");
    web3Modal.clearCachedProvider();
    setConnected(false);

    setTimeout(() => {
      window.location.reload();
    }, 1);
  }, [provider, web3Modal, connected]);

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      switchNetwork2,
      hasCachedProvider,
      provider,
      connected,
      address,
      chainID,
      web3Modal,
      Error,
    }),
    [connect, disconnect, switchNetwork2, hasCachedProvider, provider, connected, address, chainID, web3Modal],
  );

  return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>;
};
