import { getContractAddress } from "src/Addresses";

export const getVaultAddress = (chainID, elpName) => {
  const vaultAddress1 = getContractAddress(chainID, "Vault");
  if (chainID == 56 || chainID == 97) {
    const vaultAddress2 = getContractAddress(chainID, "Vault_ELP_2");
    return elpName == "ELP-1" ? vaultAddress1 : vaultAddress2
  }
  return vaultAddress1
}

export const getPositionRouterAddress = (chainID, elpName) => {
  const positionRouterAddress1 = getContractAddress(chainID, "PositionRouter");
  if (chainID == 56 || chainID == 97) {
    const positionRouterAddress2 = getContractAddress(chainID, "PositionRouter_ELP_2");
    return elpName == "ELP-1" ? positionRouterAddress1 : positionRouterAddress2
  }
  return positionRouterAddress1
}

export const getOrderBookAddress = (chainID, elpName) => {
  const orderBookAddress1 = getContractAddress(chainID, "OrderBook");
  if (chainID == 56 || chainID == 97) {
    const orderBookAddress2 = getContractAddress(chainID, "OrderBook_ELP_2");
    return elpName == "ELP-1" ? orderBookAddress1 : orderBookAddress2
  }
  return orderBookAddress1
}

export const getRouterAddress = (chainID, elpName) => {
  const routerAddress1 = getContractAddress(chainID, "Router");
  if (chainID == 56 || chainID == 97) {
    const routerAddress2 = getContractAddress(chainID, "Router_ELP_2");
    return elpName == "ELP-1" ? routerAddress1 : routerAddress2
  }
  return routerAddress1
}

export const getPositionManagerAddress = (chainID, elpName) => {
  const positionManagerAddress1 = getContractAddress(chainId, "PositionManager");
  if (chainID == 56 || chainID == 97) {
    const positionManagerAddress2 = getContractAddress(chainId, "PositionManager_ELP_2");
    return elpName == "ELP-1" ? positionManagerAddress1 : positionManagerAddress2
  }
  return positionManagerAddress1
}

export const getElpAddress = (chainID, elpName) => {
  const Elp1Address = getContractAddress(chainID, "ELP");
  if (chainID == 56 || chainID == 97) {
    const Elp2Address = getContractAddress(chainID, "ELP_2");
    return elpName == "ELP-1" ? Elp1Address : Elp2Address
  }
  return Elp1Address
}

export const getElpManagerAddress = (chainID, elpName) => {
  const elpManagerAddress1 = getContractAddress(chainID, "ElpManager");
  if (chainID == 56 || chainID == 97) {
    const elpManagerAddress2 = getContractAddress(chainID, "ElpManager_ELP_2");
    return elpName == "ELP-1" ? elpManagerAddress1 : elpManagerAddress2
  }
  return elpManagerAddress1
}