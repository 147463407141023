import BNBIcon from "../../assets/images/tokens/BNB.svg";
import ArbitrumIcon from "../../assets/images/tokens/Arbitrum.svg";
export type AddressEnvMap = { [chainId: number]: number };
export enum SupportedChainId {
  BSCTEST = 97,
  ARB_TEST = 421613,
  BSCMAINNET = 56,
  ARBITRUM = 42161,
}

export const NETWORK_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]: "https://goerli.arbiscan.io",
  [SupportedChainId.BSCTEST]: "https://testnet.bscscan.com",

  [SupportedChainId.ARBITRUM]: "https://arbiscan.io",
  [SupportedChainId.BSCMAINNET]: "https://bscscan.com",
};

export const RPC_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]: "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
  [SupportedChainId.BSCTEST]: "https://data-seed-prebsc-1-s1.binance.org:8545",
  [SupportedChainId.ARBITRUM]: "https://endpoints.omniatech.io/v1/arbitrum/one/public",
  [SupportedChainId.BSCMAINNET]: "https://bsc-dataseed3.ninicoin.io",
};

export const CHAINNAME_LIST = {
  [SupportedChainId.BSCTEST]: {
    chainName: "BSC Testnet",
    nativeCurrency: "BNB",
    icon: BNBIcon,
  },
  [SupportedChainId.ARB_TEST]: {
    chainName: "Arbitrum Testnet",
    nativeCurrency: "ETH",
    icon: ArbitrumIcon,
  },
  [SupportedChainId.BSCMAINNET]: {
    chainName: "BNB Smart Chain",
    nativeCurrency: "BNB",
    icon: BNBIcon,
  },

  [SupportedChainId.ARBITRUM]: {
    chainName: "Arbitrum",
    nativeCurrency: "ETH",
    icon: ArbitrumIcon,
  },
};
