import React, { useState } from "react";
import cx from "classnames";

import Tooltip2 from "../Tooltip/Tooltip";
// import PositionSeller from "./PositionSeller";
import PositionSeller from "./PositionSellerDialog";
// import PositionEditor from "./PositionEditor";
import PositionEditor from "./PositionEditorDialog";
// import OrdersToa from "./OrdersToa";
import OrdersToa from "./OrdersToaDialog";

import { ImSpinner2 } from "react-icons/im";
import PendingIcon from "../../assets/images/loading/pending.svg";

import {
  helperToast,
  bigNumberify,
  getLiquidationPrice,
  getUsd,
  getLeverage,
  formatAmount,
  getOrderError,
  USD_DECIMALS,
  FUNDING_RATE_PRECISION,
  SWAP,
  LONG,
  SHORT,
  INCREASE,
  DECREASE,
  getLiquidationPrice2,
} from "../../helpers/Helpers";
import { toastSuccess } from "src/helpers/toastHelpers";
import { Box, useMediaQuery, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const getOrdersForPosition = (account, position, orders, nativeTokenAddress) => {
  if (!orders || orders.length === 0) {
    return [];
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter(order => {
      if (order.type === SWAP) {
        return false;
      }
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position?.indexTokenInfo?.isNative
          : order.indexToken === position.indexToken;
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position?.collateralTokenInfo?.isNative
          : order.collateralToken === position.collateralToken;
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true;
      }
    })
    .map(order => {
      order.error = getOrderError(account, order, undefined, position);
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = "Order size is bigger than position, will only be executable if position increases";
      }
      return order;
    });
};

export default function PositionsList(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positions,
    positionsDataIsLoading,
    positionsMap,
    infoTokens,
    active,
    account,
    library,
    pendingTxns,
    setPendingTxns,
    setListSection,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    setIsWaitingForPluginApproval,
    approveOrderBook,
    isPluginApproving,
    isWaitingForPluginApproval,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    showPnlAfterFees,
    setMarket,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;

  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  const HtmlTooltip = withStyles(theme => ({
    arrow: {
      color: "#08071C",
      placement: "left",
    },
    tooltip: {
      backgroundColor: "#08071C",
      border: "1px solid #292865",
      color: "rgb(255,255,255,0.6)",
      minWidth: isSmallScreen ? "300px" : "370px",
      padding: "20px",
      borderRadius: "10px",
      fontSize: "13px",
      lineHeight: "20px",
    },
  }))(Tooltip);

  const [elpName, setElpName] = useState("ELP-1");
  const [positionToEditKey, setPositionToEditKey] = useState(undefined);
  const [currentPosition, setCurrentPosition] = useState(undefined);
  const [positionToSellKey, setPositionToSellKey] = useState(undefined);
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined);
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined);
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined);
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);

  const editPosition = position => {
    position.indexTokenInfo.isElp1 ? setElpName("ELP-1") : setElpName("ELP-2")
    setCurrentPosition(position);
    setCollateralTokenAddress(position.collateralToken);
    setPositionToEditKey(position.key);
    setIsPositionEditorVisible(true);
  };

  const sellPosition = position => {
    position.indexTokenInfo.isElp1 ? setElpName("ELP-1") : setElpName("ELP-2")
    setCurrentPosition(position);
    setPositionToSellKey(position.key);
    setIsPositionSellerVisible(true);
    setIsHigherSlippageAllowed(false);
  };

  const onPositionClick = position => {
    // helperToast.success(`${position.isLong ? "Long" : "Short"} ${position.indexTokenInfo.symbol} market selected`);
    toastSuccess(`${position.isLong ? "Long" : "Short"} ${position.indexTokenInfo.symbol} market selected`);
    setMarket(position.isLong ? LONG : SHORT, position.indexToken);
  };

  return (
    <div className="PositionsList">
      <PositionEditor
        elpName={elpName}
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        position={currentPosition}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        active={active}
        account={account}
        library={library}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        positionRouterApproved={positionRouterApproved}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        approvePositionRouter={approvePositionRouter}
        chainId={chainId}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
      />
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionSellerVisible && (
        <PositionSeller
          elpName={elpName}
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKey}
          position={currentPosition}
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        />
      )}
      {positions.length > 0 && (
        <div className="Exchange-list small">
          <div>
            {positions.length === 0 && positionsDataIsLoading && (
              <div className="Exchange-empty-positions-list-note App-card">Loading...</div>
            )}
            {positions.length === 0 && !positionsDataIsLoading && (
              <div className="Exchange-empty-positions-list-note App-card">No open positions</div>
            )}
            {positions.map(position => {
              const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
              // const liquidationPrice = getLiquidationPrice(position);
              const liquidationPrice = getLiquidationPrice2(position);
              const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
              const positionDelta =
                position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
              let borrowFeeText;
              if (position.collateralToken && position.collateralTokenInfo.fundingRate) {
                const borrowFeeRate = position.collateralTokenInfo.fundingRate
                  .mul(position.size)
                  .mul(24)
                  .div(FUNDING_RATE_PRECISION);
                borrowFeeText = borrowFeeRate.gt(0) ? `-$${formatAmount(borrowFeeRate, USD_DECIMALS, 2)}` : `+$${formatAmount(borrowFeeRate, USD_DECIMALS, 2)}`;
              }

              return (
                <div key={position.key} className="App-card">
                  <Box display='flex' alignItems='center' justifyContent='flex-start' className="App-card-title">
                    <img src={position?.indexTokenInfo?.imageUrl} height={16} />
                    <span className="Exchange-list-title ml-8">{position.indexTokenInfo.symbol}</span>
                  </Box>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">Leverage</div>
                      <div>
                        {formatAmount(position.leverage, 4, 2, true)}x&nbsp;
                        <span
                          className={cx("Exchange-list-side", {
                            positive: position.isLong,
                            negative: !position.isLong,
                          })}
                        >
                          {position.isLong ? "Long" : "Short"}
                        </span>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Size</div>
                      <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Collateral</div>
                      <div>
                        <Tooltip2
                          handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                          position="right-bottom"
                          handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                          renderContent={() => {
                            return (
                              <>
                                <div className="font-13 color5" >
                                  {position.hasLowCollateral && (
                                    <Box display="flex" justifyContent="space-between" className="mt-8">
                                      WARNING: This position has a low amount of collateral after deducting borrowing
                                      fees, deposit more collateral to reduce the position's liquidation risk.
                                    </Box>
                                  )}
                                  <Box display="flex" justifyContent="space-between" className="mt-8">
                                    <div className="">Initial Collateral</div>
                                    <div className="color1 text-right ml-12">
                                      ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                    </div>
                                  </Box>
                                  <Box display="flex" justifyContent="space-between" className="mt-8">
                                    <div className="">Funding Fee</div>
                                    <div className="color1 text-right ml-12">
                                      {
                                        position.fundingFee.gt(0) ? <span>-${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.fundingFee, USD_DECIMALS, 2, true))}</span>
                                      }
                                    </div>
                                  </Box>
                                  <Box display="flex" justifyContent="space-between" className="mt-8">
                                    <div className="">Premium</div>
                                    <div className="color1 text-right ml-12">
                                      {
                                        position.pendingPremiumFee.eq(0)
                                          ?
                                          <span className="color5" >-</span>
                                          :
                                          position.pendingPremiumFee.gt(0) ? <span>-${formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true))}</span>
                                      }
                                    </div>
                                  </Box>
                                  <Box display="flex" justifyContent="space-between" className="mt-8">
                                    Use the "Edit" button to deposit or withdraw collateral.
                                  </Box>
                                  {borrowFeeText &&
                                    <Box display="flex" justifyContent="space-between" className="mt-8">
                                      <div className="">Funding Fee / Day</div>
                                      <div className="color1 text-right ml-12">
                                        {borrowFeeText}
                                      </div>
                                    </Box>
                                  }
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">PnL</div>
                      <div>
                        <span
                          className={cx("Exchange-list-info-label", {
                            positive: hasPositionProfit && positionDelta.gt(0),
                            negative: !hasPositionProfit && positionDelta.gt(0),
                            muted: positionDelta.eq(0),
                          })}
                        >
                          {position.deltaStr} ({position.deltaPercentageStr})
                        </span>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Net Value</div>
                      <div>
                        <Tooltip2
                          handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                          position="right-bottom"
                          handleClassName="plain"
                          renderContent={() => {
                            return (
                              <div className="font-13 color5" >
                                <Box display="flex" justifyContent="space-between" className="mt-8">
                                  <div className="">Initial Collateral</div>
                                  <div className="color1 text-right ml-12">
                                    ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                  </div>
                                </Box>
                                <Box display="flex" justifyContent="space-between" className="mt-8">
                                  <div className="">PnL</div>
                                  <div className="color1 text-right ml-12">
                                    {position.deltaBeforeFeesStr}
                                  </div>
                                </Box>
                                <Box display="flex" justifyContent="space-between" className="mt-8">
                                  <div className="">Funding Fee</div>
                                  <div className="color1 text-right ml-12">
                                    {
                                      position.fundingFee.gt(0) ? <span>-${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.fundingFee, USD_DECIMALS, 2, true))}</span>
                                    }
                                  </div>
                                </Box>
                                <Box display="flex" justifyContent="space-between" className="mt-8">
                                  <div className="">Premium</div>
                                  <div className="color1 text-right ml-12">
                                    {
                                      position.pendingPremiumFee.eq(0)
                                        ?
                                        <span className="color5" >-</span>
                                        :
                                        position.pendingPremiumFee.gt(0) ? <span>-${formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true))}</span>
                                    }
                                  </div>
                                </Box>
                                <Box display="flex" justifyContent="space-between" className="mt-8">
                                  <div className="">Short-term Tax</div>
                                  <div className="color1 text-right ml-12">
                                    {
                                      position.termTax.eq(0)
                                        ?
                                        <span className="color5" >-</span>
                                        :
                                        position.termTax.gt(0) ? <span>-${formatAmount(position.termTax, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.termTax, USD_DECIMALS, 2, true))}</span>
                                    }
                                  </div>
                                </Box>
                                {/* <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Open fee</div>
                                <div className="color1 text-right ml-12">
                                  ${formatAmount(position.positionFee, USD_DECIMALS, 2, true)}
                                </div>
                              </Box> */}
                                <Box display="flex" justifyContent="space-between" className="mt-8">
                                  <div className=""> Close fee</div>
                                  <div className="color1 text-right ml-12">
                                    {
                                      position.closingFee.gt(0) ? <span>-${formatAmount(position.closingFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.closingFee, USD_DECIMALS, 2, true))}</span>
                                    }
                                  </div>
                                </Box>
                                <Box display="flex" justifyContent="space-between" className="mt-16">
                                  <div className="">PnL After Fees</div>
                                  <div className="color1 text-right ml-12">
                                    {position.deltaAfterFeesStr} ({position.deltaAfterFeesPercentageStr})
                                  </div>
                                </Box>
                                <Box display="flex" className="mt-16">
                                  Net Value: Initial Collateral + PnL After Fees
                                  {/* {showPnlAfterFees
                                  ? "Initial Collateral + PnL- Fees"
                                  : "Initial Collateral + PnL - Funding Fee "} */}
                                </Box>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Orders</div>
                      <div>
                        {positionOrders.length === 0 && "None"}
                        {positionOrders.map(order => {
                          const orderText = () => (
                            <>
                              {order.triggerAboveThreshold ? ">" : "<"} {formatAmount(order.triggerPrice, 30, 2, true)}:
                              {order.type === INCREASE ? " +" : " -"}${formatAmount(order.sizeDelta, 30, 2, true)}
                            </>
                          );
                          if (order.error) {
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                <Tooltip2
                                  className="order-error"
                                  handle={orderText()}
                                  position="right-bottom"
                                  handleClassName="plain"
                                  renderContent={() => <span className="negative">{order.error}</span>}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div key={`${order.isLong}-${order.type}-${order.index}`} className="Position-list-order">
                                {orderText()}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">Mark Price</div>
                      <div>${formatAmount(position.markPrice, USD_DECIMALS, position.indexTokenInfo.tokenDecimals, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Entry Price</div>
                      <div>${formatAmount(position.averagePrice, USD_DECIMALS, position.indexTokenInfo.tokenDecimals, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Liq. Price</div>
                      <div>${formatAmount(liquidationPrice, USD_DECIMALS, position.indexTokenInfo.tokenDecimals, true)}</div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-options">
                    <button
                      disabled={position.size.eq(0)}
                      className="App-button-option App-card-option"
                      onClick={() => editPosition(position)}
                    >
                      Edit
                    </button>
                    <button
                      disabled={position.size.eq(0)}
                      className="App-button-option App-card-option"
                      onClick={() => sellPosition(position)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <table className="Exchange-list large App-box">
        <tbody>
          <tr className="Exchange-list-header">
            <th>Position</th>
            <th>Net Value</th>
            <th>Size</th>
            <th>Collateral</th>
            <th>Mark Price</th>
            <th>Entry Price</th>
            <th>Liq. Price</th>
            <th></th>
            <th></th>
          </tr>
          {positions.length === 0 && positionsDataIsLoading && (
            <tr>
              <td colSpan="15">
                <div className="Exchange-empty-positions-list-note">Loading...</div>
              </td>
            </tr>
          )}
          {positions.length === 0 && !positionsDataIsLoading && (
            <tr>
              <td colSpan="15">
                <div className="Exchange-empty-positions-list-note">No open positions</div>
              </td>
            </tr>
          )}
          {positions.map(position => {
            // const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0);
            const liquidationPrice = getLiquidationPrice2(position) || bigNumberify(0);
            const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress);
            const hasOrderError = !!positionOrders.find(order => order.error);
            const hasPositionProfit = position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"];
            const positionDelta =
              position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] || bigNumberify(0);
            let borrowFeeText;
            if (position.collateralToken && position.collateralTokenInfo.fundingRate) {
              const borrowFeeRate = position.collateralTokenInfo.fundingRate
                .mul(position.size)
                .mul(24)
                .div(FUNDING_RATE_PRECISION);
              borrowFeeText = borrowFeeRate.gt(0) ? `-$${formatAmount(borrowFeeRate, USD_DECIMALS, 2)}` : `+$${Math.abs(formatAmount(borrowFeeRate, USD_DECIMALS, 2))}`;
            }

            return (
              <tr key={position.key}>
                <td className="clickable" onClick={() => onPositionClick(position)}>
                  <Box display='flex' alignItems='center' className="Exchange-list-title">
                    <img src={position?.indexTokenInfo?.imageUrl} height={16} />
                    <div className="ml-8"> {position.indexTokenInfo.symbol}</div>
                    {position.hasPendingChanges && <ImSpinner2 className="spin position-loading-icon ml-8" />}
                  </Box>
                  <div className="Exchange-list-info-label" style={{ marginTop: '8px' }}>
                    {position.leverage && (
                      <span className="muted">
                        {formatAmount(position.leverage, 4, 2, true)}x&nbsp;
                      </span>
                    )}
                    <span className={cx({ positive: position.isLong, negative: !position.isLong })}>
                      {position.isLong ? "Long" : "Short"}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    {!position.netValue && <Box display="flex" justifyContent="center" alignItems="center">
                      <div className="">Opening</div>
                      <img src={PendingIcon} height={20} className="ml-12" />
                    </Box>}
                    {position.netValue &&
                      <Tooltip2
                        handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                        position="left-bottom"
                        handleClassName="plain"
                        renderContent={() => {
                          return (
                            <div className="font-13 color5" >
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Initial Collateral</div>
                                <div className="color1 text-right ml-12">
                                  ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                </div>
                              </Box>
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">PnL</div>
                                <div className="color1 text-right ml-12">
                                  {position.deltaBeforeFeesStr}
                                </div>
                              </Box>
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Funding Fee</div>
                                <div className="color1 text-right ml-12">
                                  {
                                    position.fundingFee.gt(0) ? <span>-${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.fundingFee, USD_DECIMALS, 2, true))}</span>
                                  }
                                </div>
                              </Box>
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Premium</div>
                                <div className="color1 text-right ml-12">
                                  {
                                    position.pendingPremiumFee.eq(0)
                                      ?
                                      <span className="color5" >-</span>
                                      :
                                      position.pendingPremiumFee.gt(0) ? <span>-${formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true))}</span>
                                  }
                                </div>
                              </Box>
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Short-term Tax</div>
                                <div className="color1 text-right ml-12">
                                  {
                                    position.termTax.eq(0)
                                      ?
                                      <span className="color5" >-</span>
                                      :
                                      position.termTax.gt(0) ? <span>-${formatAmount(position.termTax, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.termTax, USD_DECIMALS, 2, true))}</span>
                                  }
                                </div>
                              </Box>
                              {/* <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Open fee</div>
                                <div className="color1 text-right ml-12">
                                  ${formatAmount(position.positionFee, USD_DECIMALS, 2, true)}
                                </div>
                              </Box> */}
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className=""> Close fee</div>
                                <div className="color1 text-right ml-12">
                                  {
                                    position.closingFee.gt(0) ? <span>-${formatAmount(position.closingFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.closingFee, USD_DECIMALS, 2, true))}</span>
                                  }
                                </div>
                              </Box>
                              <Box display="flex" justifyContent="space-between" className="mt-16">
                                <div className="">PnL After Fees</div>
                                <div className="color1 text-right ml-12">
                                  {position.deltaAfterFeesStr} ({position.deltaAfterFeesPercentageStr})
                                </div>
                              </Box>
                              <Box display="flex" className="mt-16">
                                Net Value: Initial Collateral + PnL After Fees
                                {/* {showPnlAfterFees
                                  ? "Initial Collateral + PnL- Fees"
                                  : "Initial Collateral + PnL - Funding Fee "} */}
                              </Box>
                            </div>
                          );
                        }}
                      />
                    }
                  </div>
                  {
                    position.deltaStr && (
                      <div
                        className={cx("Exchange-list-info-label", {
                          positive: hasPositionProfit && positionDelta.gt(0),
                          negative: !hasPositionProfit && positionDelta.gt(0),
                          muted: positionDelta.eq(0),
                        })}
                      >
                        {position.deltaStr} ({position.deltaPercentageStr})
                      </div>
                    )
                  }
                </td>
                <td>
                  <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                  {positionOrders.length > 0 && (
                    <div onClick={() => setListSection && setListSection("Orders")}>
                      <Tooltip2
                        handle={`Orders (${positionOrders.length})`}
                        position="left-bottom"
                        handleClassName={cx(
                          ["Exchange-list-info-label", "Exchange-position-list-orders", "plain", "clickable"],
                          { muted: !hasOrderError, negative: hasOrderError },
                        )}
                        renderContent={() => {
                          return (
                            <>
                              <strong>Active Orders</strong>
                              {positionOrders.map(order => {
                                return (
                                  <div
                                    key={`${order.isLong}-${order.type}-${order.index}`}
                                    className="Position-list-order"
                                  >
                                    {order.triggerAboveThreshold ? ">" : "<"}{" "}
                                    {formatAmount(order.triggerPrice, 30, 2, true)}:
                                    {order.type === INCREASE ? " +" : " -"}${formatAmount(order.sizeDelta, 30, 2, true)}
                                    {order.error && (
                                      <>
                                        , <span className="negative">{order.error}</span>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          );
                        }}
                      />
                    </div>
                  )}
                </td>
                <td>
                  <Tooltip2
                    handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                    position="left-bottom"
                    handleClassName={cx("plain", { negative: position.hasLowCollateral })}
                    renderContent={() => {
                      return (
                        <>
                          <div className="font-13 color5" >
                            {position.hasLowCollateral && (
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                WARNING: This position has a low amount of collateral after deducting borrowing
                                fees, deposit more collateral to reduce the position's liquidation risk.
                              </Box>
                            )}
                            <Box display="flex" justifyContent="space-between" className="mt-8">
                              <div className="">Initial Collateral</div>
                              <div className="color1 text-right ml-12">
                                ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                              </div>
                            </Box>
                            <Box display="flex" justifyContent="space-between" className="mt-8">
                              <div className="">Funding Fee</div>
                              <div className="color1 text-right ml-12">
                                {
                                  position.fundingFee.gt(0) ? <span>-${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}</span> : <span>+${formatAmount(Math.abs(position.fundingFee, USD_DECIMALS, 2, true))}</span>
                                }
                              </div>
                            </Box>
                            <Box display="flex" justifyContent="space-between" className="mt-8">
                              <div className="">Premium</div>
                              <div className="color1 text-right ml-12">
                                {
                                  position.pendingPremiumFee.eq(0)
                                    ?
                                    <span className="color5" >-</span>
                                    :
                                    position.pendingPremiumFee.gt(0) ? <span>-${formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true)}</span> : <span>+${Math.abs(formatAmount(position.pendingPremiumFee, USD_DECIMALS, 2, true))}</span>
                                }
                              </div>
                            </Box>
                            <Box display="flex" justifyContent="space-between" className="mt-8">
                              Use the "Edit" button to deposit or withdraw collateral.
                            </Box>
                            {borrowFeeText &&
                              <Box display="flex" justifyContent="space-between" className="mt-8">
                                <div className="">Funding Fee / Day</div>
                                <div className="color1 text-right ml-12">
                                  {borrowFeeText}
                                </div>
                              </Box>
                            }
                          </div>
                        </>
                      );
                    }}
                  />
                </td>
                <td className="clickable" onClick={() => onPositionClick(position)}>
                  <Tooltip2
                    handle={`$${formatAmount(position.markPrice, USD_DECIMALS, position.indexTokenInfo.tokenDecimals, true)}`}
                    position="left-bottom"
                    handleClassName="plain clickable"
                    renderContent={() => {
                      return (
                        <>
                          Click on a row to select the position's market, then use the swap box to increase your
                          position size if needed.
                          <br />
                          <br />
                          Use the "Close" button to reduce your position size, or to set stop-loss / take-profit orders.
                        </>
                      );
                    }}
                  />
                </td>
                <td className="clickable" onClick={() => onPositionClick(position)}>
                  ${formatAmount(position.averagePrice, USD_DECIMALS, position.indexTokenInfo.tokenDecimals, true)}
                </td>
                <td className="clickable" onClick={() => onPositionClick(position)}>
                  ${formatAmount(liquidationPrice, USD_DECIMALS, position.indexTokenInfo.tokenDecimals, true)}
                </td>
                <td>
                  <button
                    className="Exchange-list-action editBtn"
                    onClick={() => editPosition(position)}
                    disabled={position.size.eq(0)}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="Exchange-list-action closeBtn"
                    onClick={() => sellPosition(position)}
                    disabled={position.size.eq(0)}
                  >
                    Close
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table >
    </div >
  );
}
