import { Box, Link } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./footer.scss";
import Logo from "../../assets/images/logo.svg";
import LogoM from "../../assets/images/footer/logo.svg";
import Telegram from "../../assets/images/footer/telegram.svg";
import Twitter from "../../assets/images/footer/twitter.svg";
import Discord from "../../assets/images/footer/discord.svg";

export default function Footer() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");

  return (
    <Box display="flex" justifyContent="center" className="footer-view">
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems="center"
        justifyContent="space-between"
        className="container"
      >
        <img src={isSmallScreen ? LogoM : Logo} alt="logo" className="logo" />
        <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center" className="linkList">
          <Box display="flex" className="linkList1">
            <div className="linkItem">
              <a href="https://stats.ede.finance/" target={"_blank"} className="color2">
                Stats
              </a>
            </div>
            <div className="linkItem">
              <a href="https://docs.ede.finance/" target={"_blank"} className="color2">
                Docs
              </a>
            </div>
          </Box>
          <Box display="flex" className="linkList2">
            <div className="linkItem">
              <a href="https://twitter.com/ede_finance" target={"_blank"} className="color2">
                <img src={Twitter} />
              </a>
            </div>
            <div className="linkItem">
              <a href="https://t.me/ede_finance" target={"_blank"} className="color2">
                <img src={Telegram} />
              </a>
            </div>
            <div className="linkItem">
              <a href="https://discord.com/invite/g7GpVVxtxz" target={"_blank"} className="color2">
                <img src={Discord} />
              </a>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
