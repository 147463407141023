import { setAll, getTokenPrice } from "../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IBaseAsyncThunk, IBaseAddressAsyncThunk } from "./interfaces";
import { addresses } from "../configs/constants";
import { ethers } from "ethers";
import { abi as gEdeYieldDistributorABI } from "../abis/gEdeYieldDistributor.json";
import { abi as EdeStakeABI } from "../abis/edeStake.json";
import { abi as EdeABI } from "../abis/EDE.json";
import moment from "moment";
import { getEdePrice } from "src/hooks/getPrice_ede";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";

const getEndTime = async (
  networkID: number,
  address: string,
  signer: ethers.Signer | ethers.providers.Provider | undefined,
) => {
  const gedeContract = new ethers.Contract(addresses[networkID].veStaker, EdeStakeABI, signer);
  const lockedInfo = await gedeContract.locked(address || PLACEHOLDER_ACCOUNT);
  const currtimestamp = moment().valueOf();
  const currtimestampS = (currtimestamp / 1000).toFixed(0);
  const endTime = Number(lockedInfo[1]) - Number(currtimestampS);
  return endTime;
};

export const loadEarnDetails = createAsyncThunk(
  "earn/loadEarn",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {

    // console.log("loadEarnDetails");
    const signer = provider.getSigner();

    const currentBlock = await provider.getBlockNumber();


    const edeContract = new ethers.Contract(addresses[networkID].EDE, EdeABI, provider);
    const gedeContract = new ethers.Contract(addresses[networkID].veStaker, EdeStakeABI, provider);
    const gedeBalance = Number(ethers.utils.formatUnits(await gedeContract.balanceOfAtNow(address || PLACEHOLDER_ACCOUNT), 18));

    const EdeYieldDistributorContract = new ethers.Contract(
      addresses[networkID].edeDistribution,
      gEdeYieldDistributorABI,
      provider,
    );

    const weekRewards_ede = Number(
      ethers.utils.formatUnits(await EdeYieldDistributorContract.getYieldForDuration(), 18),
    );
    const EusdYieldDistributorContract = new ethers.Contract(
      addresses[networkID].eusdDistribution,
      gEdeYieldDistributorABI,
      provider,
    );

    const weekRewards_eusd = Number(
      ethers.utils.formatUnits(await EusdYieldDistributorContract.getYieldForDuration(), 18),
    );

    const totalSupply_ede = Number(ethers.utils.formatUnits(await edeContract.totalSupply(), 18));
    const totalSupply_gede = Number(ethers.utils.formatUnits(await gedeContract.totalSupplyAtNow(), 18));

    const totalStaked_ede = Number(ethers.utils.formatUnits(await gedeContract.totalEDESupply(), 18));

    const edePrice = await getEdePrice({ networkID, provider });

    let edeApr1 = 0;
    let edeApr2 = 0;
    let eusdApr1 = 0;
    let eusdApr2 = 0;
    // console.log("weekRewards_eusd", weekRewards_eusd, "totalSupply_gede", totalSupply_gede)
    if (weekRewards_ede > 0 && totalSupply_gede > 0) {
      edeApr1 = ((weekRewards_ede / 7) * 365) / totalSupply_gede; //*(1 + 3 / 4 / 365 * 7)
      edeApr2 = (((weekRewards_ede / 7) * 365) / totalSupply_gede) * 4;
    }
    if (weekRewards_eusd > 0 && totalSupply_gede > 0) {
      eusdApr1 = ((weekRewards_eusd / edePrice / 7) * 365) / totalSupply_gede; //* (1 + 3 / 4 / 365 * 7)
      eusdApr2 = (((weekRewards_eusd / edePrice / 7) * 365) / totalSupply_gede) * 4;
    }
    // console.log("edeApr1", edeApr1, "edeApr2", edeApr2, "eusdApr1", eusdApr1, "eusdApr2", eusdApr1)
    const minApr = edeApr1 + eusdApr1;
    const maxApr = edeApr2 + eusdApr2;

    const lockedInfo = await gedeContract.locked(address || PLACEHOLDER_ACCOUNT);
    const edeStakedAmount = Number(ethers.utils.formatUnits(lockedInfo[0], 18));
    const edeApr = (gedeBalance / edeStakedAmount) * edeApr1; // / (1 + 3 / 4 / 365 * 7)
    const eusdApr = (gedeBalance / edeStakedAmount) * eusdApr1; // / (1 + 3 / 4 / 365 * 7)
    const estApr = edeApr + eusdApr;

    let endTime = await getEndTime(networkID, address || PLACEHOLDER_ACCOUNT, provider);

    return {
      gedeBalance,
      edeApr1,
      edeApr2,
      eusdApr1,
      eusdApr2,
      minApr,
      maxApr,
      edeApr,
      eusdApr,
      estApr,
      endTime,
    };
  },
);

const initialState = {
  loading: false,
};

const earnSlice = createSlice({
  name: "earn",
  initialState,
  reducers: {
    fetchEarnSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadEarnDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadEarnDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadEarnDetails.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.earn;
export default earnSlice.reducer;
export const { fetchEarnSuccess } = earnSlice.actions;
export const getEarnState = createSelector(baseInfo, earn => earn);
