import BigNumber from "bignumber.js";

export async function getTokenPrice() {
  return 973.2735583177802;
}

export function shorten(str: string) {
  if (str.length < 10) return str;
  return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

export function formatCurrency(c: number, precision = 0) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }).format(c);
}

export function trim(number = 0, precision = 0) {
  // why would number ever be undefined??? what are we trimming?
  const array = number.toString().split(".");
  if (array.length === 1) return number.toString();
  if (precision === 0) return array[0].toString();

  const poppedNumber = array.pop() || "";
  array.push(poppedNumber.substring(0, precision));
  const trimmedNumber = array.join(".");
  return trimmedNumber;
}

export const calTime2 = (seconds: number) => {
  if (seconds !== 0 && !seconds) {
    return "";
  }
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds - 24 * 3600 * d - h * 3600 - m * 60);
  const dDisplay = d > 0 ? (d >= 10 ? d : "0" + d) : "00";
  const hDisplay = h > 0 ? (h >= 10 ? h : "0" + h) : "00";
  const mDisplay = m > 0 ? (m >= 10 ? m : "0" + m) : "00";
  const sDisplay = s > 0 ? (s >= 10 ? s : "0" + s) : "00";
  let result = { day: dDisplay, hour: hDisplay, minutes: mDisplay, seconds: sDisplay };
  return result;
};

export const calTime = (seconds: number) => {
  if (seconds !== 0 && !seconds) {
    return "";
  }
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds - 24 * 3600 * d - h * 3600 - m * 60);
  const dDisplay = d > 0 ? (d >= 10 ? d : "0" + d) : "00";
  const hDisplay = h > 0 ? (h >= 10 ? h : "0" + h) : "00";
  const mDisplay = m > 0 ? (m >= 10 ? m : "0" + m) : "00";
  let result = { day: dDisplay, hour: hDisplay, minutes: mDisplay };
  return result;
};

export function prettifySeconds(seconds: number, resolution?: string) {
  if (seconds !== 0 && !seconds) {
    return "";
  }

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (resolution === "day") {
    return d + (d == 1 ? " day" : " days");
  }

  const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";

  let result = dDisplay + hDisplay + mDisplay;
  if (mDisplay === "") {
    result = result.slice(0, result.length - 2);
  }

  return result;
}

// TS-REFACTOR-NOTE - Used for:
// AccountSlice.ts, AppSlice.ts, LusdSlice.ts
export function setAll(state: any, properties: any) {
  const props = Object.keys(properties);
  props.forEach(key => {
    state[key] = properties[key];
  });
}

/*
 * returns unix timestamp for x minutes ago
 * @param x minutes as a number
 */
export const minutesAgo = (x: number) => {
  const now = new Date().getTime();
  return new Date(now - x * 60000).getTime();
};

/**
 * subtracts two dates for use in 33-together timer
 * param (Date) dateA is the ending date object
 * param (Date) dateB is the current date object
 * returns days, hours, minutes, seconds
 * NOTE: this func previously used parseInt() to convert to whole numbers, however, typescript doesn't like
 * ... using parseInt on number params. It only allows parseInt on string params. So we converted usage to
 * ... Math.trunc which accomplishes the same result as parseInt.
 */
export const subtractDates = (dateA: Date, dateB: Date) => {
  let msA: number = dateA.getTime();
  let msB: number = dateB.getTime();

  let diff: number = msA - msB;

  let days: number = 0;
  if (diff >= 86400000) {
    days = Math.trunc(diff / 86400000);
    diff -= days * 86400000;
  }

  let hours: number = 0;
  if (days || diff >= 3600000) {
    hours = Math.trunc(diff / 3600000);
    diff -= hours * 3600000;
  }

  let minutes: number = 0;
  if (hours || diff >= 60000) {
    minutes = Math.trunc(diff / 60000);
    diff -= minutes * 60000;
  }

  let seconds: number = 0;
  if (minutes || diff >= 1000) {
    seconds = Math.trunc(diff / 1000);
  }
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export function timestampToTime(timestamp: number) {
  var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + "-";
  var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  var D = date.getDate() + " ";
  // var h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
  // var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
  // var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  //return Y + M + D + h + m + s;
  return Y + M + D;
}

export function timestampToMin(timestamp: number) {
  var cDate = new Date().getTime() / 1000;
  var data = timestamp - cDate;
  const m = Math.floor((data % 3600) / 60);
  const mDisplay = m > 0 ? m + "min" : "-";
  return mDisplay;
}

export const numberToHex = (val: any, decimals: any) => {
  var num_str = new BigNumber(val).multipliedBy(new BigNumber(10).pow(decimals));
  var x = new BigNumber(num_str);
  return x.toFixed(0);
};

export const hexToNumber = (val: any, decimals: number = 18) => {
  // console.log("hexToNumber",val,decimals,val / Math.pow(10, decimals),accDiv(val,Math.pow(10, decimals))
  var num_str = new BigNumber(val).div(new BigNumber(10).pow(decimals));
  var x = new BigNumber(num_str);
  return x.toFixed(0);
};

export const BIG_TEN = new BigNumber(10);

export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals));
};

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals));
};
