import { Box, useMediaQuery } from "@material-ui/core";
import "./loading.scss";
import Loading from "../../assets/images/loading2.svg";

export default function LoadingSplash() {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="loading-splash">
      <img src={Loading} style={{ height: isVerySmallScreen ? "50px" : "100px" }} />
      <div className="mt-12">loading...</div>
    </Box>
  );
}
