import { Box, Button, Dialog, Drawer, Link, SvgIcon } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import copy from "copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks";
import { success } from "src/slices/MessagesSlice";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import PendingIcon from "../../assets/images/loading/pending.svg";
import Close from "../../assets/images/close.svg";
import Close2 from "../../assets/images/close2.svg";
import Discord from "../../assets/images/discord.svg";
import { ReactComponent as Down } from "../../assets/images/down.svg";
import Errors from "../../assets/images/error_.svg";
import Github from "../../assets/images/github.svg";
import { ReactComponent as Wallet } from "../../assets/images/header/wallet.svg";
import Logo from "../../assets/images/logo.svg";
import LogoM from "../../assets/images/logoM.svg";
import Menu from "../../assets/images/menu.svg";
import { ReactComponent as Setting } from "../../assets/images/setting.svg";
import { ReactComponent as SettingM } from "../../assets/images/setting-m.svg";
import Twitter from "../../assets/images/twitter.svg";
import { CHAINNAME_LIST, NETWORK_LIST } from "src/constants/chains";
import { shorten } from "../../helpers";
import useClaim from "../../hooks/claim";
import "../../views/dashboard/dialog.scss";
import Checkbox from "../Checkbox/Checkbox2";
import "./header.scss";
import { toastSuccess } from "src/helpers/toastHelpers";
import { toastError } from "../../helpers/toastHelpers";
import { SupportedChainId } from "src/constants/chains";
export default function Header(props) {
  const {
    slippageAmount,
    onInputValueChange,
    saveSettings,
    showPnlAfterFees,
    setShowPnlAfterFees,
    isPnlInLeverage,
    setIsPnlInLeverage,
    openSettings,
    showSetting,
    setShowSetting,
  } = props;
  const isSmallScreen = useMediaQuery("(max-width: 1380px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 1280px)");
  const smallScreen = useMediaQuery("(max-width: 680px)");

  const dispatch = useDispatch();
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const { connect, disconnect, connected, web3, address, chainID, switchNetwork2, Error } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleClose2 = () => {
    setShowInfo(false);
  };
  const handleClose3 = () => {
    setShowSetting(false);
  };
  const saveAndCloseSettings = () => {
    saveSettings();
  };

  const handlerLogout = disconnect;
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  let buttonText = "Connect Wallet";
  let clickFunc = () => {
    if (isConnected) {
      setShowInfo(true);
    } else {
      connect(true);
    }
  };

  if (isConnected) {
    buttonText = shorten(address); // "Disconnect";
    // clickFunc = disconnect;
  }

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  const { claimTokens } = useClaim();
  const getTokens = async () => {
    await claimTokens();
  };

  const handlerNetwork = () => {
    setShow(true);
  };

  const handlerSwitch = (chainId, chainName, nativeCurrency, rpcUrl, blockExplorer) => {
    // console.log(chainId, chainName, nativeCurrency, rpcUrl, blockExplorer);
    switchNetwork2(chainId, chainName, nativeCurrency, rpcUrl, blockExplorer);
  };

  const chain_ID = SupportedChainId[SupportedChainId[chainID]];

  return (
    <div className="header">
      <Box display="flex" alignItems="center" className="header-view">
        <Box display="flex" alignItems="center" justifyContent="space-between" className="header">
          <Box display="flex" alignItems="center" className="">
            <img src={isSmallScreen ? LogoM : Logo} alt="logo" className="logo" />
            {!isVerySmallScreen && (
              <Box display="flex" className="menuList font-14 font-weight-6">
                <Link component={NavLink} to={`/`} className={`menuItem ${pathname == "/" && "menuItemActive"}`}>
                  Dashboard
                </Link>
                <Link
                  component={NavLink}
                  to={`/Trade`}
                  className={`menuItem ${pathname == "/Trade" && "menuItemActive"}`}
                >
                  Trade
                </Link>
                <Link
                  component={NavLink}
                  to={`/Earn`}
                  className={`menuItem ${pathname == "/Earn" && "menuItemActive"}`}
                >
                  Earn
                </Link>
                <Link
                  component={NavLink}
                  to={`/EUSD`}
                  className={`menuItem ${pathname == "/EUSD" && "menuItemActive"}`}
                >
                  EUSD
                </Link>
                <Link
                  component={NavLink}
                  to={`/Leaderboard`}
                  className={`menuItem ${pathname == "/Leaderboard" && "menuItemActive"}`}
                >
                  Leaderboard
                </Link>
                <Link
                  component={NavLink}
                  to={`/Governance`}
                  className={`menuItem ${pathname == "/Governance" && "menuItemActive"}`}
                >
                  Governance
                </Link>
                <Link
                  component={NavLink}
                  to={`/ESBT`}
                  className={`menuItem ${pathname == "/ESBT" && "menuItemActive"}`}
                >
                  ESBT
                </Link>
              </Box>
            )}
          </Box>
          {isVerySmallScreen ? (
            <Box display="flex" alignItems="center" className="color2 font-weight-5">
              <img src={Menu} alt="menu" className="menu" onClick={toggleDrawer} />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="connectBtnBox ml-12"
                onClick={clickFunc}
              >
                <Button variant="contained" className="connectBtn ml-12">
                  <SvgIcon className="ml-4 downIcon" component={Wallet} viewBox="0 0 13 12" />
                  <span className="ml-12">{buttonText}</span>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box display="flex" className="color2 font-weight-5">
              {/* <Box display="flex" justifyContent="center" alignItems="center" className="getBtnBox" onClick={getTokens}>
                <Button variant="contained" className="getBtn" disabled={isPendingTxn(pendingTransactions, "claim")}>
                  {isPendingTxn(pendingTransactions, "claim") ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <div className="">Pending</div>
                      <img src={PendingIcon} height={20} className="ml-12" />
                    </Box>
                  ) : (
                    txnButtonText(pendingTransactions, "claim", "Get Test Tokens")
                  )}
                </Button>
              </Box> */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="switchBtnBox ml-12"
                onClick={handlerNetwork}
              >
                {!Error ? (
                  <Button variant="contained" className="switchBtn">
                    <img src={CHAINNAME_LIST[chainID].icon} height={20} />
                    <div className="ml-8">{CHAINNAME_LIST[chainID].chainName}</div>
                    <SvgIcon className="ml-4 downIcon" component={Down} viewBox="0 0 15 9" />
                  </Button>
                ) : (
                  <Button variant="contained" className="switchBtn">
                    <img src={Errors} />
                    <div className="ml-8">Unsupported</div>
                    <SvgIcon className="ml-4 downIcon" component={Down} viewBox="0 0 15 9" />
                  </Button>
                )}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="connectBtnBox ml-12"
                onClick={clickFunc}
              >
                <Button variant="contained" className="connectBtn ml-12">
                  <SvgIcon className="ml-4 downIcon" component={Wallet} viewBox="0 0 13 12" />
                  <span className="ml-12">{buttonText}</span>
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="setting ml-12"
                onClick={openSettings}
              >
                <SvgIcon className="downIcon" component={Setting} viewBox="0 0 21 21" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog open={showSetting} onClose={handleClose3}>
        <div className="dialogBg dialogBg-wallet">
          <div className="dialogContent">
            <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
              <div className="">
                <div className="font-18 font-weight-b">Setting</div>
              </div>
              <img src={Close} onClick={handleClose3} />
            </Box>
            <div className="settingInfo">
              <div className="font-14 font-weight-6 color6">Allowed Slippage</div>
              <Box display="flex" alignItems="center" justifyContent="space-between" className="stakedAmount mt-12">
                <input
                  type="number"
                  min="0"
                  placeholder="0.0"
                  className="amount-input flex-1"
                  value={slippageAmount}
                  onChange={onInputValueChange}
                />
                <div className="font-15 color4 font-weight-5">%</div>
              </Box>
              <div className="Exchange-settings-row mt-32">
                <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
                  <div>Display PnL after fees</div>
                </Checkbox>
              </div>
              <div className="Exchange-settings-row mt-20">
                <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
                  <div>Include PnL in leverage display</div>
                </Checkbox>
              </div>
              <div className={`getBtnBox mt-24`} onClick={saveAndCloseSettings}>
                <Button variant="contained" fullWidth className="getBtn" style={{ fontSize: 16, height: 40 }}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={showInfo} onClose={handleClose2}>
        <div className="dialogBg dialogBg-wallet">
          <div className="dialogContent">
            <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
              <div className="">
                <div className="font-18 font-weight-b">My Wallet</div>
              </div>
              <img src={Close} onClick={handleClose2} />
            </Box>
            <div className="networkList font-16">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`networkItem ${connected && chainID == chain_ID ? "networkItemActive" : ""}`}
              >
                <Box display="flex" alignItems="center" className="networkItemLeft">
                  <div className="ml-12">{shorten(address)}</div>
                </Box>
                <Box display="flex" alignItems="center" className="isConnected">
                  <div className="font-14">Connected</div>
                  <div className="connected"></div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                alignItems="center"
                className="mt-24"
              >
                <div
                  className="connectBtnBox"
                  onClick={() => {
                    copy(address),
                      // dispatch(success("copy success"));
                      toastSuccess("copy success");
                  }}
                >
                  <Button variant="contained" className="connectBtn ml-12">
                    <span className="">Copy Address</span>
                  </Button>
                </div>
                <div
                  className={`connectBtnBox ${smallScreen && "mt-12"}`}
                  onClick={() => {
                    if (chainID == 97) window.open(`https://testnet.bscscan.com/address/${address}`);
                    if (chainID == 56) window.open(`https://bscscan.com/address/${address}`);
                  }}
                >
                  <Button variant="contained" className="connectBtn ml-12">
                    <span className="">View in Explorer</span>
                  </Button>
                </div>
                <div className={`getBtnBox ${smallScreen && "mt-12"}`} onClick={handlerLogout}>
                  <Button variant="contained" className="getBtn">
                    Disconnect
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={show} onClose={handleClose}>
        <div className="dialogBg dialogBg-network">
          <div className="dialogContent">
            <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
              <div className="">
                <div className="font-18 font-weight-b">Switch Networks</div>
              </div>
              <img src={Close} onClick={handleClose} />
            </Box>
            <div className="networkList font-16">
              {NETWORK_LIST.map((item, index) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  key={index}
                  className={`networkItem ${connected && chainID == item.chainId ? "networkItemActive" : ""}`}
                  onClick={() =>
                    handlerSwitch(item.chainId, item.chainName, item.nativeCurrency, item.rpcUrl, item.blockExplorerUrl)
                  }
                >
                  <Box display="flex" alignItems="center" className="networkItemLeft">
                    <img src={item.icon} height={30} />
                    <div className="ml-12">{item.chainName}</div>
                  </Box>
                  {connected && chainID == item.chainId && (
                    <Box display="flex" alignItems="center" className="isConnected">
                      <div className="font-14">Connected</div>
                      <div className="connected"></div>
                    </Box>
                  )}
                </Box>
              ))}
            </div>
          </div>
        </div>
      </Dialog>
      <Drawer anchor={"right"} open={showDrawer} onClose={toggleDrawer}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" className="drawerBg">
          <div className="">
            <Box display="flex" justifyContent="flex-end" className="drawerClose" onClick={toggleDrawer}>
              <img src={Close2} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end" className="menuList font-14 font-weight-6">
              <Link
                component={NavLink}
                to={`/`}
                className={`menuItem ${pathname == "/" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                Dashboard
              </Link>
              <Link
                component={NavLink}
                to={`/Trade`}
                className={`menuItem ${pathname == "/Trade" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                Trade
              </Link>
              <Link
                component={NavLink}
                to={`/Earn`}
                className={`menuItem ${pathname == "/Earn" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                Earn
              </Link>
              <Link
                component={NavLink}
                to={`/EUSD`}
                className={`menuItem ${pathname == "/EUSD" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                EUSD
              </Link>
              <Link
                component={NavLink}
                to={`/Leaderboard`}
                className={`menuItem ${pathname == "/Leaderboard" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                Leaderboard
              </Link>
              <Link
                component={NavLink}
                to={`/Governance`}
                className={`menuItem ${pathname == "/Governance" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                Governance
              </Link>
              <Link
                component={NavLink}
                to={`/ESBT`}
                className={`menuItem ${pathname == "/ESBT" && "menuItemActive"}`}
                onClick={toggleDrawer}
              >
                ESBT
              </Link>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end" className="color2 font-weight-5">
              {/* <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="getBtnBox"
                onClick={getTokens}
                style={{ width: "172px" }}
              >
                <Button variant="contained" className="getBtn" disabled={isPendingTxn(pendingTransactions, "claim")}>
                  {isPendingTxn(pendingTransactions, "claim") ? (
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <div className="">Pending</div>
                      <img src={PendingIcon} height={20} className="ml-12" />
                    </Box>
                  ) : (
                    txnButtonText(pendingTransactions, "claim", "Get Test Tokens")
                  )}
                </Button>
              </Box> */}
              {isConnected && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="switchBtnBox ml-12"
                  onClick={isConnected ? handlerNetwork : clickFunc}
                  style={{ width: "172px" }}
                >
                  {!Error ? (
                    <Button variant="contained" className="switchBtn">
                      <img src={CHAINNAME_LIST[chainID].icon} height={20} />
                      <div className="ml-8">{CHAINNAME_LIST[chainID].chainName}</div>
                      <SvgIcon className="ml-4 downIcon" component={Down} viewBox="0 0 15 9" />
                    </Button>
                  ) : (
                    <Button variant="contained" className="switchBtn">
                      <div className="">Unsupported</div>
                    </Button>
                  )}
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="connectBtnBox ml-12"
                onClick={clickFunc}
                style={{ width: "172px" }}
              >
                <Button variant="contained" className="connectBtn ml-12">
                  <SvgIcon className="ml-4 downIcon" component={Wallet} viewBox="0 0 13 12" />
                  <span className="ml-12">{buttonText}</span>
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className="connectBtnBox ml-12"
                onClick={openSettings}
                style={{ width: "172px" }}
              >
                <Button variant="contained" className="connectBtn ml-12">
                  <SvgIcon className="downIcon" component={SettingM} viewBox="0 0 14 14" />
                  <span className="ml-12">Settings</span>
                </Button>
              </Box>
            </Box>
          </div>
          <Box display="flex" justifyContent="flex-end" alignItems="center" className="linkList">
            <a href="" target={"_blank"} className="linkItem">
              <img src={Github} />
            </a>
            <a href="https://twitter.com/ede_finance" target={"_blank"} className="linkItem">
              <img src={Twitter} />
            </a>
            <a href="https://discord.com/invite/g7GpVVxtxz" target={"_blank"} className="linkItem">
              <img src={Discord} />
            </a>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
