import { createMulticall, ListenerOptions } from "@uniswap/redux-multicall";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "src/constants/chains";
import { useInterfaceMulticall } from "src/hooks/useContract";
import useBlockNumber from "src/lib/hooks/useBlockNumber";
import { useMemo } from "react";
import { useWeb3Context } from "src/hooks";

const multicall = createMulticall();

export default multicall;

function getBlocksPerFetchForChainId(chainId: number | undefined): number {
  switch (chainId) {
    // case SupportedChainId.ARBITRUM_ONE:
    //   return 15;
    // case SupportedChainId.CELO:
    // case SupportedChainId.CELO_ALFAJORES:
    //   return 5;
    default:
      return 15;
  }
}

export function MulticallUpdater() {
  const { chainID: chainId } = useWeb3Context();
  const latestBlockNumber = useBlockNumber();
  const contract = useInterfaceMulticall();
  const listenerOptions: ListenerOptions = useMemo(
    () => ({
      blocksPerFetch: getBlocksPerFetchForChainId(chainId),
    }),
    [chainId],
  );

  return (
    <multicall.Updater
      chainId={chainId}
      latestBlockNumber={latestBlockNumber}
      contract={contract}
      listenerOptions={listenerOptions}
    />
  );
}
