import { Box, Dialog, Button, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { ethers } from "ethers";
import { BsArrowRight } from "react-icons/bs";

import "../../views/dashboard/dialog.scss";
import Close from "../../assets/images/close.svg";
import PendingIcon from "../../assets/images/loading/pending.svg";
import {
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  DEPOSIT_FEE,
  DUST_BNB,
  helperToast,
  formatAmount,
  bigNumberify,
  usePrevious,
  formatAmountFree,
  fetcher,
  parseValue,
  expandDecimals,
  shouldRaiseGasError,
  getTokenInfo,
  getLiquidationPrice,
  approveTokens,
  execInc,
  getLiquidationPrice2,
} from "../../helpers/Helpers";
import { getContractAddress } from "../../Addresses";
import { callContract } from "../../Api";

import PositionRouter from "../../abis/PositionRouter.json";
import Token from "../../abis/Token.json";
import Tooltip from "../Tooltip/Tooltip";
import { getConstant } from "../../configs/getConstant";
import { toastError } from "src/helpers/toastHelpers";
import { getPositionRouterAddress, getRouterAddress } from "src/helpers/elpAddress";

const DEPOSIT = "Deposit";
const WITHDRAW = "Withdraw";
const EDIT_OPTIONS = [DEPOSIT, WITHDRAW];
const { AddressZero } = ethers.constants;

export default function PositionEditor(props) {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const {
    elpName,
    pendingPositions,
    setPendingPositions,
    positionsMap,
    positionKey,
    position,
    isVisible,
    setIsVisible,
    infoTokens,
    active,
    account,
    library,
    collateralTokenAddress,
    pendingTxns,
    setPendingTxns,
    getUsd,
    getLeverage,
    savedIsPnlInLeverage,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    chainId,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;

  const handleClose = () => {
    setIsVisible(false);
  };

  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  // const position = positionsMap && positionKey ? positionsMap[positionKey] : undefined;
  const [option, setOption] = useState(DEPOSIT);
  const [fromValue, setFromValue] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isSwapping, setIsSwapping] = useState(false);
  const prevIsVisible = usePrevious(isVisible);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);

  const routerAddress = getRouterAddress(chainId, elpName);
  const positionRouterAddress = getPositionRouterAddress(chainId, elpName);

  const { data: tokenAllowance } = useSWR(
    [active, chainId, collateralTokenAddress, "allowance", account, routerAddress],
    {
      fetcher: fetcher(library, Token),
    },
  );

  // const { data: minExecutionFee } = useSWR([active, chainId, positionRouterAddress, "minExecutionFee"], {
  //   fetcher: fetcher(library, PositionRouter),
  // });

  const isDeposit = option === DEPOSIT;
  const isWithdrawal = option === WITHDRAW;

  const needPositionRouterApproval = !positionRouterApproved;

  let collateralToken;
  let maxAmount;
  let maxAmountFormatted;
  let maxAmountFormattedFree;
  let fromAmount;
  let needApprove;
  const [needApproval, setNeedApproval] = useState(true);
  useEffect(() => {
    setNeedApproval(needApprove);
  }, [needApprove]);

  let convertedAmount;
  let convertedAmountFormatted;

  let nextLeverage;
  let nextLeverageExcludingPnl;
  let liquidationPrice;
  let nextLiquidationPrice;
  let nextCollateral;

  let title;
  let collateralDelta;
  if (position) {
    title = `Edit ${position.isLong ? "Long" : "Short"} ${position.indexTokenInfo.symbol}`;
    collateralToken = position.collateralTokenInfo;
    // liquidationPrice = getLiquidationPrice(position);
    liquidationPrice = getLiquidationPrice2(position);

    if (isDeposit) {
      fromAmount = parseValue(fromValue, collateralToken.decimals);
      maxAmount = collateralToken ? collateralToken.balance : bigNumberify(0);
      maxAmountFormatted = formatAmount(maxAmount, collateralToken.decimals, 4, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, collateralToken.decimals, 8);
      if (fromAmount) {
        convertedAmount = getUsd(fromAmount, position.collateralToken, false, infoTokens);
        convertedAmountFormatted = formatAmount(convertedAmount, USD_DECIMALS, 2);
      }
    } else {
      fromAmount = parseValue(fromValue, USD_DECIMALS);
      maxAmount = position.collateral;
      maxAmountFormatted = formatAmount(maxAmount, USD_DECIMALS, 2, true);
      maxAmountFormattedFree = formatAmountFree(maxAmount, USD_DECIMALS, 2);
      if (fromAmount) {
        convertedAmount = fromAmount.mul(expandDecimals(1, collateralToken.decimals)).div(collateralToken.maxPrice);
        convertedAmountFormatted = formatAmount(convertedAmount, collateralToken.decimals, 4, true);
      }
    }
    needApprove = isDeposit && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance);

    if (fromAmount) {
      collateralDelta = isDeposit ? convertedAmount : fromAmount;
      if (position.isLong) {
        collateralDelta = collateralDelta.mul(BASIS_POINTS_DIVISOR - DEPOSIT_FEE).div(BASIS_POINTS_DIVISOR);
      }
      nextLeverage = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: savedIsPnlInLeverage,
      });
      nextLeverageExcludingPnl = getLeverage({
        size: position.size,
        collateral: position.collateral,
        collateralDelta,
        increaseCollateral: isDeposit,
        entryFundingRate: position.entryFundingRate,
        cumulativeFundingRate: position.cumulativeFundingRate,
        hasProfit: position.hasProfit,
        delta: position.delta,
        includeDelta: false,
      });

      // nextLiquidationPrice = getLiquidationPrice({
      //   isLong: position.isLong,
      //   size: position.size,
      //   collateral: position.collateral,
      //   averagePrice: position.averagePrice,
      //   entryFundingRate: position.entryFundingRate,
      //   cumulativeFundingRate: position.cumulativeFundingRate,
      //   collateralDelta,
      //   increaseCollateral: isDeposit,
      // });
      // console.log(collateralDelta, ethers.utils.formatUnits(collateralDelta, 30), "collateralDelta");
      nextLiquidationPrice = getLiquidationPrice2(position, collateralDelta);
      nextCollateral = isDeposit ? position.collateral.add(collateralDelta) : position.collateral.sub(collateralDelta);
    }
  }

  const getError = () => {
    if (!fromAmount) {
      return "Enter an amount";
    }
    if (nextLeverage && nextLeverage.eq(0)) {
      return "Enter an amount";
    }

    if (!isDeposit && fromAmount) {
      if (fromAmount.gte(position.collateral)) {
        return "Min order: 10 USD";
      }
      if (position.collateral.sub(fromAmount).lt(expandDecimals(10, USD_DECIMALS))) {
        return "Min order: 10 USD";
      }
    }

    if (!isDeposit && fromAmount && nextLiquidationPrice) {
      if (position.isLong && position.markPrice.lt(nextLiquidationPrice)) {
        return "Invalid liq. price";
      }
      if (!position.isLong && position.markPrice.gt(nextLiquidationPrice)) {
        return "Invalid liq. price";
      }
    }

    if (nextLeverageExcludingPnl && nextLeverageExcludingPnl.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return "Min leverage: 1.1x";
    }

    if (nextLeverageExcludingPnl && nextLeverageExcludingPnl.gt(30.5 * BASIS_POINTS_DIVISOR)) {
      return "Max leverage: 30x";
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isSwapping) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isSwapping) {
      if (isDeposit) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <div className="">Depositing</div>
            <img src={PendingIcon} height={20} className="ml-12" />
          </Box>
        );
      }
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Withdrawing</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }

    if (isApproving) {
      return `Approving ${position.collateralTokenInfo.symbol}...`;
    }
    if (needApproval) {
      return `Approve ${position.collateralTokenInfo.symbol}`;
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return "Enabling Leverage";
    }

    if (isPositionRouterApproving) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <div className="">Enabling Leverage</div>
          <img src={PendingIcon} height={20} className="ml-12" />
        </Box>
      );
    }

    if (needPositionRouterApproval) {
      return "Enable Leverage";
    }

    if (isDeposit) {
      return "Deposit";
    }

    return "Withdraw";
  };

  const resetForm = () => {
    setFromValue("");
  };

  useEffect(() => {
    if (prevIsVisible !== isVisible) {
      resetForm();
    }
  }, [prevIsVisible, isVisible]);

  const depositCollateral = async () => {
    setIsSwapping(true);
    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const path = [tokenAddress0];
    const indexTokenAddress = position.indexToken === AddressZero ? nativeTokenAddress : position.indexToken;

    const priceBasisPoints = position.isLong ? 11000 : 9000;
    const priceLimit = position.indexTokenInfo.maxPrice.mul(priceBasisPoints).div(10000);

    const referralCode = ethers.constants.HashZero;
    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _amountIn
      0, // _minOut
      0, // _sizeDelta
      position.isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
    ];
    let params2 = [
      path, // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _amountIn
      0, // _minOut
      0, // _sizeDelta
      position.isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
    ];
    let method = "createIncreasePosition";
    let value = minExecutionFee;
    if (collateralTokenAddress === AddressZero) {
      method = "createIncreasePositionETH";
      value = fromAmount.add(minExecutionFee);
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        0, // _sizeDelta
        position.isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
      ];
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, collateralTokenAddress), fromAmount)) {
      setIsSwapping(false);
      // helperToast.error(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
      toastError(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ETH for gas`);
      return;
    }

    const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, method, params, {
      value,
      sentMsg: "Deposit submitted.",
      successMsg: `Requested deposit of ${formatAmount(fromAmount, position.collateralTokenInfo.decimals, 4)} ${
        position.collateralTokenInfo.symbol
      } into ${position.indexTokenInfo.symbol} ${position.isLong ? "Long" : "Short"}.`,
      failMsg: "Deposit failed.",
      setPendingTxns,
    })
      .then(async res => {
        setFromValue("");
        setIsVisible(false);

        // pendingPositions[position.key] = {
        //   updatedAt: Date.now(),
        //   pendingChanges: {
        //     collateralSnapshot: position.collateral,
        //     expectingCollateralChange: true,
        //   },
        // };

        // setPendingPositions({ ...pendingPositions });
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const withdrawCollateral = async () => {
    setIsSwapping(true);
    const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
    const indexTokenAddress = position.indexToken === AddressZero ? nativeTokenAddress : position.indexToken;
    const priceBasisPoints = position.isLong ? 9000 : 11000;
    const priceLimit = position.indexTokenInfo.maxPrice.mul(priceBasisPoints).div(10000);

    const withdrawETH = collateralTokenAddress === AddressZero || collateralTokenAddress === nativeTokenAddress;
    const params = [
      [tokenAddress0], // _path
      indexTokenAddress, // _indexToken
      fromAmount, // _collateralDelta
      0, // _sizeDelta
      position.isLong, // _isLong
      account, // _receiver
      priceLimit, // _acceptablePrice
      0, // _minOut
      minExecutionFee, // _executionFee
      withdrawETH, // _withdrawETH
    ];

    const method = "createDecreasePosition";

    const contract = new ethers.Contract(positionRouterAddress, PositionRouter.abi, library.getSigner());
    callContract(chainId, contract, method, params, {
      value: minExecutionFee,
      sentMsg: "Withdrawal submitted.",
      successMsg: `Requested withdrawal of ${formatAmount(fromAmount, USD_DECIMALS, 2)} USD from ${
        position.indexTokenInfo.symbol
      } ${position.isLong ? "Long" : "Short"}.`,
      failMsg: "Withdrawal failed.",
      setPendingTxns,
    })
      .then(async res => {
        setFromValue("");
        setIsVisible(false);

        pendingPositions[position.key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            collateralSnapshot: position.collateral,
            expectingCollateralChange: true,
          },
        };
      })
      .finally(() => {
        setIsSwapping(false);
      });
  };

  const onClickPrimary = async () => {
    if (needApproval) {
      await approveTokens({
        setIsApproving,
        library,
        tokenAddress: collateralTokenAddress,
        spender: routerAddress,
        chainId: chainId,
        onApproveSubmitted: () => {
          setIsWaitingForApproval(true);
        },
        infoTokens,
        getTokenInfo,
        pendingTxns,
        setPendingTxns,
      });
      setIsWaitingForApproval(false);
      setNeedApproval(false);
      return;
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: isDeposit ? "Enable deposit sent." : "Enable withdraw sent.",
        failMsg: isDeposit ? "Enable deposit failed." : "Enable withdraw failed.",
      });
      return;
    }

    if (isDeposit) {
      depositCollateral();
      return;
    }

    withdrawCollateral();
  };
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <div className="dialogBg">
        <div className="dialogContent">
          <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
            <div className="">
              <div className="font-24 font-weight-b">{title}</div>
              <div className="desc mt-6 color2 font-14">Edit your position</div>
            </div>
            <img src={Close} onClick={handleClose} />
          </Box>
          <div className="dialogInfo">
            <Box display="flex" className="tab2 font-13 font-family-DMSans">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={`tabItem color2 ${isDeposit && "tabItemActive"}`}
                onClick={() => setOption(DEPOSIT)}
              >
                Deposit
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={`tabItem color2 ml-20 ${isWithdrawal && "tabItemActive"}`}
                onClick={() => setOption(WITHDRAW)}
              >
                Withdraw
              </Box>
            </Box>
            <div className="font-14 font-weight-6 color6">
              {isDeposit ? <div className="">Deposit</div> : <div className="">Withdraw</div>}
            </div>
            <div className="Exchange-swap-section mt-12" style={{ marginBottom: 0 }}>
              <div className="Exchange-swap-section-top">
                <div className="muted">
                  {convertedAmountFormatted && (
                    <div className="Exchange-swap-usd">
                      {isDeposit ? "Deposit" : "Withdraw"}: {convertedAmountFormatted}{" "}
                      {isDeposit ? "USD" : position?.collateralTokenInfo.symbol}
                    </div>
                  )}
                  {!convertedAmountFormatted && `${isDeposit ? "Deposit" : "Withdraw"}`}
                </div>
                {maxAmount && (
                  <div className="muted align-right clickable" onClick={() => setFromValue(maxAmountFormattedFree)}>
                    Max: {maxAmountFormatted}
                  </div>
                )}
              </div>
              <Box display="flex" className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container flex-1">
                  <input
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={fromValue}
                    onChange={e => setFromValue(e.target.value)}
                  />
                </div>
                <Box display="flex" alignItems="center">
                  {fromValue !== maxAmountFormattedFree && (
                    <div
                      className="Exchange-swap-max"
                      onClick={() => {
                        setFromValue(maxAmountFormattedFree);
                      }}
                    >
                      MAX
                    </div>
                  )}
                  <div className="PositionEditor-token-symbol ml-20">
                    {isDeposit ? position?.collateralTokenInfo.symbol : "USD"}
                  </div>
                </Box>
              </Box>
            </div>
          </div>
          <div className="summary">
            <div className="summaryInfo2 font-13 font-weight-5">
              <Box display="flex" justifyContent="space-between" alignItems="center" className="">
                <div className="color7">Size</div>
                <div className="">{formatAmount(position?.size, USD_DECIMALS, 2, true)} USD</div>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                <div className="color7">Collateral</div>
                <div className="">
                  {!nextCollateral && <div>${formatAmount(position?.collateral, USD_DECIMALS, 2, true)}</div>}
                  {nextCollateral && (
                    <div>
                      <div className="inline-block muted">
                        ${formatAmount(position?.collateral, USD_DECIMALS, 2, true)}
                        <BsArrowRight className="transition-arrow" />
                      </div>
                      ${formatAmount(nextCollateral, USD_DECIMALS, 2, true)}
                    </div>
                  )}
                </div>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                <div className="color7">Leverage</div>
                <div className="">
                  {!nextLeverage && <div>{formatAmount(position?.leverage, 4, 2, true)}x</div>}
                  {nextLeverage && (
                    <div>
                      <div className="inline-block muted">
                        {formatAmount(position?.leverage, 4, 2, true)}x
                        <BsArrowRight className="transition-arrow" />
                      </div>
                      {formatAmount(nextLeverage, 4, 2, true)}x
                    </div>
                  )}
                </div>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                <div className="color7">Mark Price</div>
                <div className="">${formatAmount(position?.markPrice, USD_DECIMALS, 2, true)}</div>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                <div className="color7">Liq. Price</div>
                <div className="">
                  {!nextLiquidationPrice && (
                    <div>
                      {!fromAmount && `$${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}`}
                      {fromAmount && "-"}
                    </div>
                  )}
                  {nextLiquidationPrice && (
                    <div>
                      <div className="inline-block muted">
                        ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                        <BsArrowRight className="transition-arrow" />
                      </div>
                      ${formatAmount(nextLiquidationPrice, USD_DECIMALS, 2, true)}
                    </div>
                  )}
                </div>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" className="mt-10">
                <div className="color7">Execution Fee</div>
                <div className="">
                  <Tooltip
                    handle={`${formatAmountFree(minExecutionFee, 18, 5)} ${nativeTokenSymbol}`}
                    position="right-top"
                    renderContent={() => {
                      return (
                        <>
                          Network fee: {formatAmountFree(minExecutionFee, 18, 5)} {nativeTokenSymbol} ($
                          {formatAmount(minExecutionFeeUSD, USD_DECIMALS, 2)})<br />
                          <br />
                          This is the network cost required to execute the {isDeposit ? "deposit" : "withdrawal"}.{" "}
                          <a
                            // href="https://gmxio.gitbook.io/gmx/trading#execution-fee"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            More Info
                          </a>
                        </>
                      );
                    }}
                  />
                </div>
              </Box>
            </div>
          </div>
          {/* <div className='operation4'>
                        <div className='editBtnBox'>
                            <Button variant="contained" className='editBtn' >
                                {getPrimaryText()}
                            </Button>
                        </div>
                    </div> */}
          <div className="Exchange-swap-button-container editBtnContainer">
            <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
